import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Menu from 'material-ui-popup-state/HoverMenu'
import MenuItem from '@material-ui/core/MenuItem'
import ChevronRight from '@material-ui/icons/ChevronRight'
import {
    usePopupState,
    bindHover,
    bindMenu,
} from 'material-ui-popup-state/hooks'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import {useHistory} from "react-router-dom";
import {Tooltip} from "@material-ui/core";

const ParentPopupState = React.createContext(null)

const MenuCascata = () => {
    const history = useHistory();

    const popupState = usePopupState({ popupId: 'demoMenu', variant: 'popover' })
    return (
        <div>
            <Tooltip title="Menu avançado" arrow>
                <IconButton {...bindHover(popupState)} variant="contained"  color="primary" ria-haspopup="true">
                    <FontAwesomeIcon color={"#fff"} size={'1x'} icon={faBars}/>
                </IconButton>
            </Tooltip>
            <ParentPopupState.Provider value={popupState}>
                <Menu
                    {...bindMenu(popupState)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    getContentAnchorEl={null}
                >
                    <Submenu popupId="Artigos" title="Artigos">
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/artigos/criar');
                        }}>Criar Artigos</MenuItem>
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/artigos');
                        }}>Listar Artigos</MenuItem>
                    </Submenu>
                    <Submenu popupId="Familias" title="Familias">
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/familias/criar');
                        }}>Criar Familia</MenuItem>
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/familias');
                        }}>Listar Familias</MenuItem>
                    </Submenu>
                    <Submenu popupId="Clientes" title="Clientes">
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/clientes/criar');
                        }}>Criar Cliente</MenuItem>
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/clientes');
                        }}>Listar Clientes</MenuItem>
                    </Submenu>
                    <Submenu popupId="Fornecedores" title="Fornecedores">
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/fornecedor/criar');
                        }}>Registar Fornecedor</MenuItem>
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/fornecedor');
                        }}>Listar Fornecedores</MenuItem>
                    </Submenu>
                    <Submenu popupId="TiposConsulta" title="Tipos de consulta">
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/tiposConsulta/criar');
                        }}>Adicionar tipo de consulta</MenuItem>
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/tiposConsulta');
                        }}>Listar Tipos de consulta</MenuItem>
                    </Submenu>
                    <Submenu popupId="Paceintes" title="Pacientes">
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/paciente/criar');
                            }}>Criar Paciente
                        </MenuItem>
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/paciente');
                            }}>Listar Pacientes
                        </MenuItem>
                        <Submenu popupId="Estados" title="Estados">
                            <MenuItem onClick={()=>{
                                popupState.close();
                                history.push('/estados');
                                }}>Ver Estados
                            </MenuItem>
                            <MenuItem onClick={()=>{
                                popupState.close();
                                history.push('/estados/criar');
                                }}>Registar estado
                            </MenuItem>
                        </Submenu>
                    </Submenu>
                    <Submenu popupId="Listagens" title="Listagens">
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/listagens/registos');
                        }}>Listagem de registos
                        </MenuItem>
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/listagens/registosValores');
                        }}>Listagem diária
                        </MenuItem>
                    </Submenu>
                    <Submenu popupId="Registos" title="Registos">
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/documentos/lista/pagos');
                            }}>Historico de registos liquidados
                        </MenuItem>
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/documentos/lista/pendente');
                            }}>Historico de registos pendentes
                        </MenuItem>
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/pagamentos');
                        }}>Recebimento</MenuItem>
                    </Submenu>
                    <Submenu popupId="Compras" title="Compras">
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/compras/listar/pagas');
                        }}>Historico de compras liquidadas
                        </MenuItem>
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/compras/listar/pendentes');
                        }}>Historico de compras pendentes
                        </MenuItem>
                        <MenuItem onClick={()=>{
                            popupState.close();
                            history.push('/pagamentosFornecedores');
                        }}>Pagamento a Fornecedor</MenuItem>
                    </Submenu>
                    <MenuItem onClick={()=>{
                        popupState.close();
                        history.push('/utilizadores');
                        }}>Listar Utilizadores
                    </MenuItem>
                    <MenuItem onClick={()=>{
                        popupState.close();
                        history.push('/atualizacoes');
                    }}>Registo de atualizações
                    </MenuItem>
                </Menu>
            </ParentPopupState.Provider>
        </div>
    )
}

export default MenuCascata

const submenuStyles = theme => ({
    menu: {
        marginTop: theme.spacing(-1),
    },
    title: {
        flexGrow: 1,
    },
    moreArrow: {
        marginRight: theme.spacing(-1),
    },
})

const Submenu = withStyles(submenuStyles)(
    // Unfortunately, MUI <Menu> injects refs into its children, which causes a
    // warning in some cases unless we use forwardRef here.
    React.forwardRef(({ classes, title, popupId, children, ...props }, ref) => {
        const parentPopupState = React.useContext(ParentPopupState)
        const popupState = usePopupState({
            popupId,
            variant: 'popover',
            parentPopupState,
        })
        return (
            <ParentPopupState.Provider value={popupState}>
                <MenuItem
                    {...bindHover(popupState)}
                    selected={popupState.isOpen}
                    ref={ref}
                >
                    <span className={classes.title}>{title}</span>
                    <ChevronRight className={classes.moreArrow} />
                </MenuItem>
                <Menu
                    {...bindMenu(popupState)}
                    classes={{ paper: classes.menu }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    getContentAnchorEl={null}
                    {...props}
                >
                    {children}
                </Menu>
            </ParentPopupState.Provider>
        )
    })
)