import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {TextField} from 'unform-material-ui';
import Select from "react-select";
import {Form} from '@unform/web';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import {useSnackbar} from "notistack";
import { useHistory } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function PacienteCriar(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [dataNascimento, setDataNascimento] = useState(moment(new Date()).subtract(1, 'month'));
    const [dono, setDono] = useState({value:undefined});
    const [estado, setEstado] = useState({value:undefined});
    const [estados, setEstados] = useState({value:undefined});
    const [sexo, setSexo] = useState({cliente:{id:undefined}});
    const [esterilizado, setEstrilizado] = useState({label:"", value:""});
    const [clientes, setClientes] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();


    async function criarPaciente(data){
        setOpen(true);
        if(dono.cliente !== undefined){
            data.estado=(typeof estado.id === undefined) ? 'indefinido' : estado.id;
            data.dataNascimento=(typeof dataNascimento === undefined) ? '1980-01-01' : dataNascimento;
            data.cliente=(typeof dono.cliente.id === undefined) ? 'indefinido' : dono.cliente.id;
            data.sexo=(typeof sexo.value === undefined) ? 'indefinido' : sexo.value;
            data.esterilizado=(typeof esterilizado.value === undefined) ? 's/n' : esterilizado.value;
            const res = await api.post('/animal', data)
            if(res.data.code===0){
                enqueueSnackbar(res.data.message, { variant: "success", autoHideDuration: 1500 });
                history.push("/paciente/"+res.data.data);
            }else{
                enqueueSnackbar(res.data.message, { variant: "error", autoHideDuration: 2500 });
            }
        }
        setOpen(false);
    }

    useEffect(()=>{
        api.get('/cliente').then(res=>setClientes(res.data.data))
        api.get('/estados').then(res=>setEstados(res.data.data))
    }, [props.location])

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Ficha de Paciente
                    </Typography>
                    <Form className={classes.form} onSubmit={criarPaciente} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={7}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    label="Nome"
                                    name="nome"
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <KeyboardDatePicker
                                    style={{marginTop: 0}}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="DD/MM/YYYY"
                                    margin="normal"
                                    invalidDateMessage={"A data não é valida"}
                                    label="Data nascimento"
                                    value={dataNascimento}
                                    onChange={date=>setDataNascimento(date)}

                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Especie"
                                    name="especie"
                                    list={"listaEspecie"}
                                    inputProps={{list:"listaEspecie"}}
                                />
                                <datalist id={"listaEspecie"}>
                                    <option value={"Gato"}>Gato</option>
                                    <option value={"Cão"}>Cão</option>
                                </datalist>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Raça"
                                    name="raca"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Cor"
                                    name="cor"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="microchip"
                                    name="microchip"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} style={{width:'25%'}} >
                                <Select
                                    onChange={op=>setDono(op)}
                                    getOptionLabel={option => option.cliente.nome}
                                    getOptionValue={option => option.cliente.id}
                                    options={clientes}
                                    variant="outlined"
                                    required
                                    placeholder="Cliente *"/>
                            </Grid>
                            <Grid item xs={12} sm={4} style={{width:'25%'}} >
                                <Select
                                    onChange={op=>setEstado(op)}
                                    getOptionLabel={option => option.descricao}
                                    getOptionValue={option => option.id}
                                    options={estados}
                                    variant="outlined"
                                    //required
                                    placeholder="Estado do animal"/>
                            </Grid>
                            <Grid item xs={12} sm={4} style={{width:'25%'}} >
                                <Select onChange={op=>setSexo(op)} name="sexo" options={[{label:"Macho", value:"macho"}, {label: "Fêmea", value: 'femea'}]} placeholder="Sexo"/>
                            </Grid>
                            <Grid item xs={12} sm={4} style={{width:'25%'}} >
                                <Select onChange={op=>setEstrilizado(op)} name="esterilizado" options={[{label:"Sim", value:"sim"}, {label: "Não", value: 'nao'}]} placeholder="Esterilizado"/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography>Pós-cirugico:</Typography>
                                <TextField
                                    variant="filled"
                                    fullWidth
                                    name="posCirurgico"
                                    multiline
                                    rows={2}
                                    rowsMax={4}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography>Observações:</Typography>
                                <TextField
                                    variant="filled"
                                    fullWidth
                                    name="observacoes"
                                    multiline
                                    rows={2}
                                    rowsMax={4}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Registar paciente
                        </Button>
                    </Form>
                </div>
                <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </MuiPickersUtilsProvider>
    );
}
