import React, {useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "react-router-dom"
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    MenuItem,
} from "@material-ui/core";
import {Select, TextField} from "unform-material-ui"
import {Scope} from "@unform/core";
import {Form} from "@unform/web";
import Grid from "@material-ui/core/Grid";
import {Alert} from "@material-ui/lab";
import {range} from "../../services/extra";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 345,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function FamiliaFicha(props) {
    const classes = useStyles();
    const [open, ] = useState(false);
    const [data, setData] = useState({
        descricao:""
    });
    const [artigos, setArtigos] = useState([]);
    const [campos, setCampos] = useState([]);
    const [descontos, setDescontos] = useState([]);
    const [showDescontos, setShowDescontos] = useState(false);
    const [message, setMessage] = useState({type:"", text:"", show:false})
    const formRef = useRef(null);


    useEffect(()=>{
        api.get('/familia/'+props.match.params.id).then(res=>{
            if(res.data.data!==[]) {
                setData(res.data.data[0])
            }
        })
        api.get('/item/familia/'+props.match.params.id).then(res=>{
            if(res.data.data!==[]){
                setArtigos(res.data.data)
            }
        })
        api.get('/familia/descontos/'+props.match.params.id).then(res=>{
            var tmp=[];
            let num=1
            for(let i of JSON.parse(res.data.data)){
                tmp=tmp.concat({num})
                num++
            }
            setCampos(tmp)
            formRef.current.setData({row:JSON.parse(res.data.data)})
            setDescontos(JSON.parse(res.data.data))

        })
    }, [props.match.params.id])

    const handleAddRule = () => {
        if(campos.length<5){
            setCampos(campos.concat({num:campos.length}))

        }
    }

    const handleRemoveRule = (idx, tipo="num") => {
        console.log(idx)
        //if(tipo==="idx"){
            //setCampos(campos.filter((s)=>s.num!==idx))
        //}else{
            setCampos(campos.filter((s,index)=>index!==idx))
        //}
    };

    function bouncer(arr) {
        console.log(arr)
        return arr.filter(function (val) {
            return Boolean(val);
        });
    }

    const saveRules =async (data)=>{
        data = bouncer(data.row)

        const res =await api.post('/familia/descontos/'+props.match.params.id, {descontos:data});
        if(res.data.code===0){
            setMessage({
                show:true,
                type: "success",
                text: res.data.message
            })
        }else{
            setMessage({
                show:true,
                type: "error",
                text: res.data.message
            })
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography component="h1" variant="h5">
                            Ficha da familia: {data.descricao}
                        </Typography>
                        <Typography fullWidth={true} component="h1" variant="h6">
                            Artigos:
                        </Typography>
                        <List component="nav" >
                            {artigos.map((value, index) => {
                                return(
                                    <Link to={"/artigos/"+value.id}  key={index}>
                                        <ListItem button >
                                            <ListItemText primary={value.descricao} />
                                        </ListItem>
                                    </Link>
                                )
                            })}

                        </List>
                        <center>
                            <Link to={"/familias/editar/" + data.id}><Button fullWidth variant={"contained"} color={"primary"}>Editar</Button></Link>
                            <Button variant={"outlined"} onClick={()=>setShowDescontos(true)} style={{marginTop: 20}} fullWidth color={"primary"}>Descontos</Button>
                        </center>
                    </CardContent>
                </Card>
            </div>
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={showDescontos}
                keepMounted
                onClose={()=>setShowDescontos(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Regras de descontos</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Criação de regra de desconto
                    </DialogContentText>
                    <Form ref={formRef} onSubmit={saveRules}>
                            <List>
                                {campos.map((data, idx)=>(
                                    <Grid container>
                                        <Scope path={"row["+(idx)+"]"}>
                                            <Grid item>
                                                <ListItem key={data.num} selected={false}>
                                                    <Select variant={"outlined"} label={"Linha de Preço"} name={"linhaPreco"}>
                                                        <MenuItem value={1}>Comum</MenuItem>
                                                        <MenuItem value={2}>Criador</MenuItem>
                                                        <MenuItem value={3}>Caçador</MenuItem>
                                                        <MenuItem value={4}>Protocolo</MenuItem>
                                                        <MenuItem value={5}>Gatil</MenuItem>
                                                    </Select>
                                                    <Divider />
                                                </ListItem>
                                            </Grid>
                                            <Grid item>
                                                <TextField variant={"outlined"} name={"desconto"} label={"Percentagem de desconto"} />
                                            </Grid>
                                        </Scope>
                                    </Grid>
                                ))}
                                <ListItem>
                                    {message.show?<Alert style={{width:'100%'}} severity={message.type}>{message.text}</Alert>:""}
                                </ListItem>
                                <ListItem>
                                    <ButtonGroup variant="contained" fullWidth={true} color="primary" aria-label="contained primary button group">
                                        <Button type="button" width="50%" disabled={campos.length>=5} onClick={handleAddRule}>Adicionar Campos</Button>
                                        <Button disabled={campos.length===0} type="button" width="50%" onClick={()=>handleRemoveRule(campos[campos.length-1].num, "idx")} color="inherit">Remover campos</Button>
                                    </ButtonGroup>
                                </ListItem>
                            </List>

                        <Button variant={"contained"} color={"primary"} fullWidth={true} type={"submit"}>Guardar</Button>
                    </Form>
                </DialogContent>
                <DialogActions>
                    {/*<Button onClick={handleClose} color="primary">
                        Disagree
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Agree
                    </Button>*/}
                </DialogActions>
            </Dialog>
        </Container>
    );
}
