import React, {useState, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import List from "@material-ui/core/List";
import {ListItem, SwipeableDrawer, Tooltip} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import SearchIcon from "@material-ui/icons/Search";
import Select from "react-select";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));




export default function PacienteListar(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [pacientes, setPacientes] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [inativos, setInativos] = React.useState(false);
    const [filtro, setFiltro] = useState(false)
    const [estados, setEstados] = useState([])
    const [listaEstados, setListaEstados] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [options, setOptions] = useState({
        nomeCliente: false,
        microchip:false,
        nif: false,
        nomeAnimal: false,
    })

    const [q, setQ]=useState("");

    useEffect(()=>{
        obter()

    }, [inativos])

    useEffect(obterEstados, [props.location])

    function obterEstados(){
        api.get('/estados').then(res=>setListaEstados(res.data.data))
    }

    const handlePageChange = page => {
        obter(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        obter(page, newPerPage);
        setPerPage(newPerPage);
    };

    function obter(page=currentPage, size=perPage, op=options, query=q){
        setLoading(true)
        api.put(`/animal?page=${page}&per_page=${size}&q=${query}&estados=${estados.join(",")}`, options).then(res=>{
            if(res.data.code===0){
                setPacientes(res.data.data)
                setTotalRows(res.data.total)

            }
            setLoading(false)
        })
    }

    function pesquisa() {
        obter()
    }

    function handleTextChange(e){
        setQ(e.target.value)
    }

    const columns = [
        {
            name:"Estado",
            cell:row => <p style={{color: row.corEstado}}>{row.estado}</p>
        },
        {
            name: "Nome",
            selector: "nome",
            sortable: true
        },{
            name: "Microchip",
            selector: "microchip",
            sortable: true
        },{
            name: "Nome tutor",
            selector: "nomeCliente",
            sortable: true
        },{
            name: "Contribuinte tutor",
            selector: "nif",
            sortable: true
        },{
            name:"",
            cell:row=><Link to={"/paciente/" + row.id}><Button variant={"contained"} color={"primary"}>Ficha</Button></Link>
        },
        {
            name:"",
            cell:row=><Link to={"/paciente/editar/" + row.id}><Button variant={"contained"} color={"primary"}>Editar</Button></Link>
        }
    ];



    return (
        <Container component="main" maxWidth="md">
            <div className={classes.paper}>
                <DataTable
                    title="Pacientes"
                    columns={columns}
                    data={pacientes}
                    sortIcon={<SortIcon />}
                    pagination
                    selectableRowsComponent={Checkbox}
                    contextMessage={{
                        singular: 'paciente',
                        plural: 'Pacientes',
                        message: 'selecionada\\s' }}
                    noDataComponent={"Não foi encontrados clientes"}
                    progressPending={loading}
                    progressComponent={<CircularProgress />}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Linhas por pagina:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'Todas'
                    }}
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationDefaultPage={currentPage}
                    actions={
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={()=>setFiltro(!filtro)}
                                endIcon={<SearchIcon>send</SearchIcon>}
                            >
                                Filtros
                            </Button>
                        </>
                    }
                />

                <SwipeableDrawer
                    anchor={'right'}
                    Backdrop={false}
                    variant="persistent"
                    BackdropProps={{ invisible: true }}
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    open={filtro}
                    onClose={()=>setFiltro(false)}
                    onOpen={()=>setFiltro(true)}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={()=>setFiltro(!filtro)}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <List>
                        <ListItem style={{"text-align": "center"}}>
                            <Typography variant="h6" noWrap>
                                Filtros
                            </Typography>
                        </ListItem>
                        <Divider />
                        <ListItem >
                            <TextField onChange={handleTextChange} value={q} label="Pesquisa" />
                        </ListItem>
                        <Divider/>
                        <ListItem button onClick={()=> {
                            if(options.nomeAnimal===true && options.nomeCliente===true && options.microchip===true && options.nif===true){
                                setOptions({
                                    nomeAnimal: false,
                                    nomeCliente: false,
                                    microchip:false,
                                    nif: false
                                })
                            }else{
                                setOptions({
                                    nomeAnimal: true,
                                    nomeCliente: true,
                                    microchip:true,
                                    nif: true
                                })
                            }
                        }}>
                            <Switch
                                checked={options.nomeAnimal===true && options.nomeCliente===true && options.microchip===true && options.nif===true}
                                name="checkedB"
                                color="primary"
                            />
                            <span>Todos</span>
                        </ListItem>
                        <Divider/>
                        <ListItem className={""} button onClick={()=> {
                            setOptions({
                                nomeAnimal: !options.nomeAnimal,
                                nomeCliente: options.nomeCliente,
                                microchip:options.microchip,
                                nif: options.nif
                            })
                        }}>
                            <Switch
                                checked={options.nomeAnimal}
                                name="checkedB"
                                color="primary"
                            />
                            <span>Nome do animal</span>
                        </ListItem>
                        <ListItem button onClick={() =>{
                            setOptions({
                                nomeAnimal: options.nomeAnimal,
                                nomeCliente: !options.nomeCliente,
                                microchip:options.microchip,
                                nif: options.nif
                            })
                        }}>
                            <Switch
                                checked={options.nomeCliente}
                                name="checkedB"
                                color="primary"
                            />
                            <span>Nome do cliente</span>
                        </ListItem>
                        <ListItem button onClick={() =>{
                            setOptions({
                                nomeAnimal: options.nomeAnimal,
                                nomeCliente:options.nomeCliente,
                                microchip:!options.microchip,
                                nif: options.nif
                            })
                        }}>
                            <Switch
                                checked={options.microchip}
                                name="checkedB"
                                color="primary"
                            />
                            <span>Microchip</span>
                        </ListItem>
                        <ListItem button onClick={() =>{
                            setOptions({
                                nomeAnimal: options.nomeAnimal,
                                nomeCliente: options.nomeCliente,
                                microchip:options.microchip,
                                nif: !options.nif
                            })
                        }}>
                            <Switch
                                checked={options.nif}
                                name="checkedB"
                                color="primary"
                            />
                            <span>Nif do cliente</span>
                        </ListItem>
                        <ListItem>
                            <Select
                                styles={{container: provided => ({
                                        ...provided,
                                        width: '100%'
                                    })}}
                                options={listaEstados}
                                isMulti
                                onChange={op=>{
                                    if(op!==null){
                                        let list=[];
                                        op.map(row=>list.push(row.id))
                                        setEstados(list)
                                    }else{
                                        setEstados([])
                                    }
                                }}
                                getOptionLabel={option => option.descricao}
                                getOptionValue={option => option.id}
                                placeholder='Estados...'
                                noOptionsMessage={()=> "Não há estados disponiveis"}
                            />
                        </ListItem>
                        <ListItem><Button onClick={pesquisa} fullWidth={true} color={"primary"} variant={"contained"}>Filtrar</Button></ListItem>

                    </List>
                </SwipeableDrawer>
            </div>
        </Container>
    );
}
