import React, {useState, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import api, {auth, url} from "../../services/api";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import List from "@material-ui/core/List";
import {ListItem, SwipeableDrawer} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Select from "react-select";
import moment from "moment"
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));




export default function ListagemRegistos() {
    const classes = useStyles();
    const theme = useTheme();

    const [registos, setRegistos] = useState([]);
    const [dataInicio, setDataIncio] = useState(moment().startOf("month"));
    const [dataFim, setDataFim] = useState(moment().endOf('month'));
    const [confirmada, setConfirmada] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [filtro, setFiltro] = useState(false)
    const [totalConfirmado, setTotalConfirmado] = useState("")
    const [totalNaoConfirmado, setTotalNaoConfirmado] = useState("")
    const [q, setQ]=useState("");

    const getData=async ()=>{
        obter()
    }

    const handlePageChange = page => {
        obter(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        obter(page, newPerPage);
        setPerPage(newPerPage);
    };

    function handleTextChange(e){
        setQ(e.target.value)
    }

    function obter(page=currentPage, size=perPage, /*op=options,*/ query=q){
        setLoading(true)
        api.get(`/listagem/registos?page=${page}&per_page=${size}&dataInicio=${dataInicio.tz('Europe/Lisbon').format('YYYY/MM/DD')}&dataFim=${dataFim.tz('Europe/Lisbon').format('YYYY/MM/DD')}&confirm=${confirmada.join(',')}`, /*options*/).then(res=>{
            if(res.data.code===0){
                setRegistos(res.data.data)
                setTotalRows(res.data.total)
                setTotalConfirmado(res.data.totalConfirmado)
                setTotalNaoConfirmado(res.data.totalNaoConfirmado)
            }
            setLoading(false)
        })
    }

    function pesquisa() {
        obter()
    }

    useEffect(()=>{
        getData()
    }, [])

    const columns = [
        {
            name: "Cliente",
            selector: "cliente",
            sortable: true
        },
        {
            name: "Valor",
            selector: "total",
            sortable: true
        },
        {
            name:"Informação",
            cell:row=><p>{row.confirmado==1?"Não confirmado":"Confirmado"}</p>
        },,
        {
            name:"",
            cell:row=><Link to={"/documentos/ver/" + row.id}><Button variant={"contained"} color={"primary"}>Detalhes</Button></Link>
        },
        {
            name:"",
            cell:row=><a target={"_blank"} rel="noopener noreferrer" href={url+"pdf/docVenda/"+ row.id+"/"+auth()}><Button variant="contained" color="primary" fullWidth={true}>Obter documento</Button></a>
        }
    ];



    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Container component="main" maxWidth="md">
                <div className={classes.paper}>
                    <DataTable
                        title="Registos"
                        columns={columns}
                        data={registos}
                        sortIcon={<SortIcon />}
                        pagination
                        selectableRowsComponent={Checkbox}
                        noDataComponent={"Não foram encontrados registos"}
                        progressPending={loading}
                        progressComponent={<CircularProgress />}
                        paginationComponentOptions={{
                            rowsPerPageText: 'Linhas por pagina:',
                            rangeSeparatorText: 'de',
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: 'Todas'
                        }}
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationDefaultPage={currentPage}
                        actions={
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={()=>setFiltro(!filtro)}
                                    endIcon={<SearchIcon>send</SearchIcon>}
                                >
                                    Filtros
                                </Button>
                            </>
                        }
                    />
                    <Grid container={true} spacing={2}>
                        <Grid item xs={4}><p>Total confirmado: {totalConfirmado}</p></Grid>
                        <Grid item xs={4}><p>Total não confirmado: {totalNaoConfirmado}</p></Grid>
                        <Grid item xs={4}><p>Total: {(parseFloat(totalNaoConfirmado.replace("€", '')) + parseFloat(totalConfirmado)).toFixed(2)+"€"}</p></Grid>
                    </Grid>


                    <SwipeableDrawer
                        anchor={'right'}
                        Backdrop={false}
                        variant="persistent"
                        BackdropProps={{ invisible: true }}
                        className={classes.drawer}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        open={filtro}
                        onClose={()=>setFiltro(false)}
                        onOpen={()=>setFiltro(true)}
                    >
                        <div className={classes.drawerHeader}>
                            <IconButton onClick={()=>setFiltro(!filtro)}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </div>
                        <List>
                            <ListItem style={{"text-align": "center"}}>
                                <Typography variant="h6" noWrap>
                                    Filtros
                                </Typography>
                            </ListItem>
                            <Divider/>
                            <ListItem >
                                <KeyboardDatePicker variant="inline" format="DD/MM/YYYY"  value={dataInicio}  onChange={date=>setDataIncio(date)} label="Data de inicio" />
                            </ListItem>
                            <ListItem >
                                <KeyboardDatePicker variant="inline" format="DD/MM/YYYY"  value={dataFim}  onChange={date=>setDataFim(date)} label="Data de Fim" />
                            </ListItem>
                            <ListItem >
                                <Select
                                    styles={{container: provided => ({
                                            ...provided,
                                            width: '100%'
                                        })}}
                                    options={[
                                        {label:"Confirmadas", value:"2"},{label: "Não confirmadas", value: '1'}
                                    ]}
                                    isMulti
                                    onChange={op=>{
                                        if(op!==null){
                                            let list=[];
                                            op.map(row=>list.push(row.value))
                                            setConfirmada(list)
                                        }else{
                                            setConfirmada([])
                                        }
                                    }}
                                    placeholder='...'
                                    noOptionsMessage={()=> "Não há estados disponiveis"}
                                />
                            </ListItem>

                            <ListItem><Button onClick={pesquisa} fullWidth={true} color={"primary"} variant={"contained"}>Filtrar</Button></ListItem>

                        </List>
                    </SwipeableDrawer>
                </div>
            </Container>
        </MuiPickersUtilsProvider>
    );
}
