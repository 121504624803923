import {isAuthenticated} from "./auth";
import React from "react";
import {  Route,  Redirect } from "react-router-dom";
import Template from "../components/Template";

export default function PrivateRoute ({ component: Component, ...rest }) {
    return(
        <Route
            {...rest}
            render={props =>
                isAuthenticated() ? (
                    <Template>
                        <Component {...props} />
                    </Template>
                ) : (
                    <Redirect to={{pathname: "/", state: {from: props.location}}} from={'/'}/>
                )
            }
        />
    )
};
