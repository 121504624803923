import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { TextField} from 'unform-material-ui';
import {Form} from '@unform/web';
import DialogActions from "@material-ui/core/DialogActions";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 345,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function UtilizadorFicha(props) {
    const classes = useStyles();
    const [data, setData] = useState({});
    const [password, setPassword] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        api.get('/user/' + props.match.params.id).then(res => {
            setData(res.data.data[0])
        })
    }, [props.match.params.id])

    function alterar(data) {
        setPassword(false)
        setOpen(true)
        api.put('/user/password/'+props.match.params.id,data).then(async res=>{
            setOpen(false)
            await Swal.fire({
                title: "Informação",
                text: res.data.message,
                icon: res.data.code === 0 ? "success" : "error",
                timer: 3000
            })
        })

    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography component="h1" variant="h6">
                            Ficha do Utilizador: {data.nome}
                        </Typography>
                        <Typography component="h1" variant="h6">
                            Nome de utilizador: {data.username}
                        </Typography>
                        <center>
                            <Button variant={"contained"} onClick={()=>setPassword(true)} color={"primary"}>Alterar password</Button>
                        </center>
                    </CardContent>
                </Card>
            </div>
            {/* ALTERAR PASSWORD */}
            <Dialog open={password} onClose={()=>setPassword(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Alterar password</DialogTitle>
                <DialogContent>
                    <Form onSubmit={alterar}>
                        <TextField
                            margin="dense"
                            label="Passowrd atual"
                            type="password"
                            name="passwordVelha"
                            autoComplete="password"
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            label="Nova passowrd"
                            type="password"
                            name="password_nova"
                            autoComplete="new-password"
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            label="Nova passowrd confirmação"
                            type="password"
                            name="password_nova_confirm"
                            autoComplete="new-password"
                            fullWidth
                        />
                        <center>
                            <Button style={{marginTop:5}} fullWidth variant={"contained"} type="submit" color={"primary"}>Atualizar password</Button>
                        </center>
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setPassword(false)} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={password} onClose={()=>setPassword(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Alterar password</DialogTitle>
                <DialogContent>
                    <Form onSubmit={alterar}>
                        <TextField
                            margin="dense"
                            label="Passowrd atual"
                            type="password"
                            name="passwordVelha"
                            autoComplete="password"
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            label="Nova passowrd"
                            type="password"
                            name="password_nova"
                            autoComplete="new-password"
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            label="Nova passowrd confirmação"
                            type="password"
                            name="password_nova_confirm"
                            autoComplete="new-password"
                            fullWidth
                        />
                        <center>
                            <Button style={{marginTop:5}} fullWidth variant={"contained"} type="submit" color={"primary"}>Atualizar password</Button>
                        </center>
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setPassword(false)} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}
