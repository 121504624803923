import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));




export default function ClientesListar() {
    const classes = useStyles();

    const [clientes, setClientes] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [inativos, setInativos] = React.useState(false);

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [q, setQ]=useState("");

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    useEffect(()=>{
        obter();
        // eslint-disable-next-line
    }, [currentPage, perPage, inativos])

    function obter(page=currentPage, size=perPage, query=q){
        setLoading(true)
        if(inativos===true){
            api.get(`/cliente/all?page=${page}&per_page=${size}&q=${query}`).then(res=>{
                if(res.data.code===0){
                    setClientes(res.data.data)
                    setTotalRows(res.data.totalRows)
                }
                setLoading(false)
            })
        }else{
            api.get(`/cliente/filter?page=${page}&per_page=${size}&q=${query}`).then(res=>{
                if(res.data.code===0){
                    setClientes(res.data.data)
                    setTotalRows(res.data.totalRows)
                }
                setLoading(false)

            })
        }
    }

    function pesquisa(e) {
        setQ(e.target.value)
        obter(currentPage, perPage, e.target.value)
    }

    const columns = [
        {
            name: "Nome",
            selector: "nome",
            sortable: true
        },
        {
            name:"",
            cell:row=><Link to={"/clientes/" + row.id}><Button variant={"contained"} color={"primary"}>Ficha</Button></Link>
        },
        {
            name:"",
            cell:row=><Link to={"/clientes/editar/" + row.id}><Button variant={"contained"} color={"primary"}>Editar</Button></Link>
        }
    ];



    return (
        <Container component="main" maxWidth="md">
            <TextField label="Pesquisa" onChange={pesquisa}/>
            <FormControlLabel
                control={
                    <Switch
                        checked={inativos}
                        onChange={()=>setInativos(!inativos)}
                        name="checkedB"
                        color="primary"
                    />
                }
                label="Mostrar inativos"
            />
            <div className={classes.paper}>
                <DataTable
                    title="Clientes"
                    columns={columns}
                    data={clientes}
                    sortIcon={<SortIcon />}
                    pagination
                    selectableRowsComponent={Checkbox}
                    contextMessage={{
                        singular: 'artigo',
                        plural: 'artigos',
                        message: 'selecionada\\s' }}
                    noDataComponent={"Não foi encontrados clientes"}
                    progressPending={loading}
                    progressComponent={<CircularProgress />}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Linhas por pagina:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'Todas'
                    }}

                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationDefaultPage={currentPage}
                />
            </div>
        </Container>
    );
}
