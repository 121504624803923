import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import PrivateRoute from "./services/PrivateRoute";
import Login from "./pages/Login";
import FamiliaCriar from "./pages/familias/Criar";
import FamiliaListar from "./pages/familias/Listar";
import FamiliaEditar from "./pages/familias/Editar";
import ArtigosCriar from "./pages/artigos/Criar";
import ArtigoListar from "./pages/artigos/Listar";
import ArtigosEditar from "./pages/artigos/Editar";
import ArtigoListarTodos from "./pages/artigos/ListarTodos";
import FamiliaFicha from "./pages/familias/Ficha";
import ArtigoFicha from "./pages/artigos/Ficha";
import ClientesListar from "./pages/clientes/Listar";
import ClienteCriar from "./pages/clientes/Criar";
import ClienteFicha from "./pages/clientes/Ficha";
import ClienteEditar from "./pages/clientes/Editar";
import DocumentosCriar from "./pages/documentos/Criar";
import DocumentosListarPagos from "./pages/documentos/ListarPagos";
import DocumentosListarPorPagar from "./pages/documentos/ListarPorPagar";
import DocumentosVer from "./pages/documentos/VerDocumento";
import DocumentosEditar from "./pages/documentos/Editar";
import UtilizadoresListar from "./pages/utilizadores/Listar";
import UtilizadorFicha from "./pages/utilizadores/Ficha";
import Dashboard from "./pages/Dashboard";
import Pagamento from "./pages/pagamentos/Pagamento";
import PacienteFicha from "./pages/pacientes/Ficha";
import PacienteCriar from "./pages/pacientes/Criar";
import PacienteEditar from "./pages/pacientes/Editar";
import PacienteListar from "./pages/pacientes/Listar";
import ComprasListarPendentes from "./pages/compras/ListarPendentes";
import ComprasListarPagas from "./pages/compras/ListarPagas";
import ComprasVer from "./pages/compras/VerDocumento";
import ComprasCriar from "./pages/compras/Criar";
import ComprasEditar from "./pages/compras/EditarDocumento";
import FornecedorCriar from "./pages/fornecedor/Criar";
import RealizarConsulta from "./pages/consulta/RealizarConsulta";
import ConsultasListar from "./pages/consulta/ListarConsultas";
import ConsultasProximasListar from "./pages/consulta/ListarProximasConsultas";
import EstadosListar from "./pages/estados/Listar";
import EstadosCriar from "./pages/estados/Criar";
import EstadosEditar from "./pages/estados/Editar";
import TiposConsultaCriar from "./pages/tiposConsulta/Criar";
import TiposConsultaListar from "./pages/tiposConsulta/Listar";
import TiposConsultaEditar from "./pages/tiposConsulta/Editar";
import Atualizacoes from "./pages/Updates";
import ListagemRegistos from "./pages/listagens/Registos";
import ListagemValor from "./pages/listagens/RegistosValor";
import {Auth} from "./pages/Auth";
import FornecedorListar from "./pages/fornecedor/Listar";
import PagamentosFornecedores from "./pages/pagamentosFornecedores/PagamentoFornecedores";

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/auth/:authToken" component={Auth} />


            <PrivateRoute exact path="/familias" component={FamiliaListar} />
            <PrivateRoute path="/familias/criar" component={FamiliaCriar} />
            <PrivateRoute path="/familias/editar/:id" component={FamiliaEditar} />
            <PrivateRoute exact path="/familias/:id" component={FamiliaFicha} />

            <PrivateRoute exact path="/artigos" component={ArtigoListar}/>
            <PrivateRoute exact path="/artigos/todos" component={ArtigoListarTodos}/>
            <PrivateRoute exact path="/artigos/criar" component={ArtigosCriar}/>
            <PrivateRoute exact path="/artigos/editar/:id" component={ArtigosEditar}/>
            <PrivateRoute exact path="/artigos/:id" component={ArtigoFicha}/>

            <PrivateRoute exact path="/clientes" component={ClientesListar} />
            <PrivateRoute exact path="/consultas/proximas" component={ConsultasProximasListar} />
            <PrivateRoute exact path="/clientes/criar" component={ClienteCriar} />
            <PrivateRoute exact path="/clientes/editar/:id" component={ClienteEditar} />
            <PrivateRoute exact path="/clientes/:id" component={ClienteFicha} />

            <PrivateRoute exact path="/consultas" component={ConsultasListar} />

            <PrivateRoute exact path="/fornecedor" component={FornecedorListar} />
            <PrivateRoute exact path="/fornecedor/criar" component={FornecedorCriar} />
            <PrivateRoute exact path="/fornecedor/editar/:id" component={ClienteEditar} />
            <PrivateRoute exact path="/fornecedor/:id" component={ClienteFicha} />

            <PrivateRoute exact path="/paciente/criar" component={PacienteCriar} />
            <PrivateRoute exact path="/paciente" component={PacienteListar} />
            <PrivateRoute exact path="/paciente/editar/:id" component={PacienteEditar} />
            <PrivateRoute exact path="/paciente/:id" component={PacienteFicha} />


            <PrivateRoute exact path="/documentos" component={DocumentosCriar} />
            <PrivateRoute exact path="/documentos/lista/pagos" component={DocumentosListarPagos} />
            <PrivateRoute exact path="/documentos/lista/pendente" component={DocumentosListarPorPagar} />
            <PrivateRoute exact path="/documentos/ver/:id" component={DocumentosVer} />
            <PrivateRoute exact path="/documentos/editar/:id" component={DocumentosEditar} />

            <PrivateRoute exact path={"/compras"} component={ComprasCriar} />
            <PrivateRoute exact path={"/compras/listar/pendentes"} component={ComprasListarPendentes} />
            <PrivateRoute exact path={"/compras/listar/pagas"} component={ComprasListarPagas} />
            <PrivateRoute exact path={"/compras/ver/:id"} component={ComprasVer} />
            <PrivateRoute exact path={"/compras/editar/:id"} component={ComprasEditar} />

            {/*<PrivateRoute exact path="/tara" component={DevolverTara} />*/}
            <PrivateRoute exact path="/pagamentos" component={Pagamento} />
            <PrivateRoute exact path="/pagamentosFornecedores" component={PagamentosFornecedores} />

            <PrivateRoute exact path="/utilizadores" component={UtilizadoresListar} />
            <PrivateRoute exact path="/utilizadores/:id" component={UtilizadorFicha} />

            <PrivateRoute path="/dashboard" component={Dashboard} />

            <PrivateRoute path="/consulta/:id" component={RealizarConsulta} />

            <PrivateRoute exact path="/estados" component={EstadosListar} />
            <PrivateRoute path="/estados/criar" component={EstadosCriar} />
            <PrivateRoute path="/estados/editar/:id" component={EstadosEditar} />

            <PrivateRoute path="/tiposConsulta/criar" component={TiposConsultaCriar} />
            <PrivateRoute path="/tiposConsulta/editar/:id" component={TiposConsultaEditar} />
            <PrivateRoute path="/tiposConsulta" component={TiposConsultaListar} />

            <PrivateRoute path="/listagens/registosValores" component={ListagemValor} />
            <PrivateRoute path="/listagens/registos" component={ListagemRegistos} />

            <PrivateRoute exact path="/atualizacoes" component={Atualizacoes} />

            <Route path="*" component={() => <h1>Page not found</h1>} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
