import React, {useEffect, useRef, useState} from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, CardHeader,
    DialogTitle, Divider,
    TableCell,
    TextField
} from "@material-ui/core";
import api, {auth, url} from "../../services/api";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "@material-ui/core/Button";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Select from "react-select";
import {useSnackbar} from "notistack";
import CreateIcon from '@material-ui/icons/Create';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import PacienteEditar from "../pacientes/Editar";
import Swal from "sweetalert2";
import {DropzoneArea} from 'material-ui-dropzone';
import ClienteFicha from "../clientes/Ficha";

const useStyles = makeStyles((theme) => ({
    root:{
        minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "35vw",

    }
}));

export default function RealizarConsulta(props){
    const classes = useStyles();

    const [infoConsulta, setInfoConsulta] = useState({
        historicoPeso:[],
        historicoVacinas:[],
        historico:[]
    });
    const [vacinas, setVacinas] = useState([]);
    const [material, setMaterial] = useState([]);
    const [artigoVacina, setArtigoVacina] = useState("");
    const [artigo, setArtigo] = useState({artigo:{id:"",descricao:"", artigo:""}, quantidade:""});
    const [dataInicio, setDataInicio] = useState(new Date());
    const [dataFim, setDataFim] = useState({});
    const [artigos, setArtigos] = useState({});
    const [sub, setSub] = useState(false);
    const [peso, setPeso] = useState("");
    const [observacoes, setObservacoes] = useState("");
    const quantidadeRef=useRef(null);
    const [editarPaciente, setEditarPaciente] = useState(false)
    const [marcarConsulta, setMarcarConsulta] = useState(false)
    const [fichaCliete, setFichaCliente] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [receitas, setReceitas] = useState([]);
    const [consultas, setConsultas] = useState([]);
    const [receitasMedicas, setReceitasMedicas] = useState([]);
    const [funcionario, setFuncionario] = useState([]);
    const [tiposConsulta, setTiposConsulta] = useState([]);
    const [marcacao, setMarcacao] = useState({
        start: moment(),
        end: moment().add(30, 'minutes'),
        funcionario: null,
        animal: null,
        tipoConsulta: null,
        info:""
    });

    useEffect(()=>{
        getData()
        api.get('/item').then(res=>{
            if(res.data.code===0){
                setArtigos(res.data.data)
            }
        })
        // eslint-disable-next-line
    }, [props.match.params.id, editarPaciente]);

    function getData(){
        api.get('/consulta/'+props.match.params.id).then(res=>{
            if(res.data.code===0){
                setInfoConsulta(res.data.data)
                setDataInicio(res.data.data.dataInicio)
                setDataFim(res.data.data.dataFim)
                setPeso(res.data.data.custo)
                setVacinas(res.data.data.vacinasUtilizadas)
                setMaterial(res.data.data.materiaisUsados)
                setObservacoes(res.data.data.obsConsulta)
                setReceitasMedicas(res.data.data.receitas)
            }
        })
        api.get('/user').then(res=>{
            setFuncionario(res.data.data)
        })
        api.get('/tiposConsulta').then((res)=>{
            setTiposConsulta(res.data.data)
        })
    }

    function adicionarVacina(){
        const vacinaNome=artigoVacina;
        if(vacinaNome!==""){
            setArtigoVacina("");
            setVacinas(prevState => [...prevState, vacinaNome]);
        }
    }

    function adicionarMatrial(){
        if(artigo.artigo.descricao!=="" && artigo.quantidade!==""){
            setArtigo({artigo:{id:"",descricao:"", artigo: ""}, quantidade:""});
            setMaterial(prevState => [...prevState, artigo]);
        }
    }

    function enviarFicheiros(){
        const form = new FormData();
        receitas.map((file, idx)=>form.append('file_'+idx, file));
        api.post('/consulta/receita/'+props.match.params.id, form, {
            headers: { "Content-Type": "multipart/form-data" },
        }).then(res=>{
            if(res.data.code===0){
                setReceitas([]);
            }
            enqueueSnackbar(res.data.message, {variant: res.data.code===0?"success":"error", autoHideDuration: 1500})
        })
    }

    function removerReceita (id){
        Swal.fire({
            title: 'Deseja remover esta receita?',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Cancelar`,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                api.delete('/receita/'+id).then(res=>{
                    Swal.close()
                    enqueueSnackbar(res.data.message, {variant: res.data.code===0?"success":"error", autoHideDuration: 1500})
                    getData()
                })
            }
        })
    }
    
    function novaMarcacao() {
        setMarcarConsulta(false)
        api.post('consulta/agendar', {
            funcionario : marcacao.funcionario,
            animal: infoConsulta.animal_id,
            dataInicio: moment(marcacao.start).tz('Europe/Lisbon').format('YYYY/MM/DD HH:mm:ss ZZ'),
            dataFim:moment(marcacao.end).tz('Europe/Lisbon').format('YYYY/MM/DD HH:mm:ss ZZ'),
            tipoConsulta: marcacao.tipoConsulta,
            info:marcacao.info
        }).then(res=>{
            if(res.data.code===0){
                enqueueSnackbar(res.data.message, { variant: "success", autoHideDuration: 1500 });

                api.get('/consulta/pendentes').then((res)=>{
                    const events = (res.data.data || []).map(item => {
                        item['start']  = item['start'] && moment(item['start']).toDate()
                        item['end'] = item['end'] && moment(item['end']).toDate()
                        return item
                    })
                })
                setMarcacao({
                    start: moment(),
                    end: moment().add(30, 'minutes'),
                    funcionario: null,
                    animal: null,
                    tipoConsulta: null,
                    info:""

                })
            }else{
                enqueueSnackbar(res.data.message, { variant: "error", autoHideDuration: 1500 });
            }
        })
    }

    function removerMaterial(idxRemove){
        setMaterial(material.filter((data, idx)=>idx!==idxRemove));
    }

    function removerVacina(idxRemove){
        setVacinas(vacinas.filter((data, idx)=>idx!==idxRemove));
    }

    function handleVacina(e){
        setArtigoVacina(e.target.value)
    }

    function handleArtigo(e, campo){
        if(campo==="artigo"){
            setArtigo({
                artigo: {
                    id: e.id,
                    descricao: e.descricao,
                    artigo:e.artigo
                },
                quantidade: artigo.quantidade
            })
        }else{
            setArtigo({
                artigo: artigo.artigo,
                quantidade: e.target.value,
            })
        }
    }

    function changeData(data, tempo){
        if(tempo==="inicio"){
            setDataInicio(data)
            setDataFim(moment(data).add(5, 'minutes'))
        }else{
            if(moment(data).diff(dataInicio)>0){
                setDataFim(data)
            }
        }
    }

    async function inserir (){
        if(sub===false){
            setSub(true);
            const materiais=[];
            // eslint-disable-next-line array-callback-return
            material.map(data=>{
                materiais.push({
                    id: data.artigo.id,
                    quantidade: data.quantidade
                })
            })
            const res = await api.put('consulta/realizar/'+props.match.params.id, {
                peso:peso,
                observacoes:observacoes,
                vacinas:vacinas,
                materiais: materiais,
            })
            enqueueSnackbar(res.data.message, {variant: res.data.code===0?"success":"error", autoHideDuration: 1500})
            setSub(false);
        }
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Container component="main" maxWidth="xs" >
                <CssBaseline />
                <div className={classes.paper} >
                    <Card className={classes.root} variant="outlined" style={{minHeight: '70vh'}}>
                        <CardContent>
                            <CardHeader title={"Realizar Consulta"}/>
                            <Grid container spacing={2}>
                                {/*Consulta*/}
                                <Grid item xs={12} sm={6} >
                                    <Accordion defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className={classes.heading}>Consulta</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <KeyboardDateTimePicker
                                                        variant="inline"
                                                        format="DD/MM/YYYY HH:mm"
                                                        margin="normal"
                                                        style={{minWith:'100%'}}
                                                        value={dataInicio}
                                                        openTo={"hours"}
                                                        ampm={false}
                                                        inputVariant="outlined"
                                                        onChange={(data)=>changeData(data, "inicio")}
                                                        label="Data Inicio consulta"
                                                        invalidDateMessage={"A data introduzida não é valida"}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <KeyboardDateTimePicker
                                                        variant="inline"
                                                        format="DD/MM/YYYY HH:mm"
                                                        margin="normal"
                                                        value={dataFim}
                                                        onChange={(data)=>changeData(data, "fim")}
                                                        minDate={moment(dataInicio).add(5, 'minutes')}
                                                        openTo={"hours"}
                                                        ampm={false}
                                                        inputVariant="outlined"
                                                        label="Data Fim consulta"
                                                        invalidDateMessage={"A data introduzida não é valida"}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField fullWidth={true} label={"Peso (KG)"} type={"number"} variant={"outlined"} onChange={e=>setPeso(e.target.value)} value={peso}/>
                                                </Grid>
                                                <Grid item xs={12} sm={12} style={{marginTop: 20}}>
                                                    <Typography component="span" variant="b">Relatorio</Typography>
                                                    <TextField value={observacoes} onChange={e=>setObservacoes(e.target.value)} variant={"filled"} fullWidth={true} multiline rows={10} rowsMax={100}/>
                                                </Grid>
                                                <Grid item sm={6} xs={12} >
                                                    <Button fullWidth={true} onClick={()=>setFichaCliente(true)} color={"primary"} variant={"contained"}>Ficha de cliente</Button>
                                                </Grid>
                                                <Grid item sm={6} xs={12} >
                                                    <Button fullWidth={true} onClick={()=>setMarcarConsulta(true)} color={"primary"} variant={"contained"}>Marcar consulta</Button>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                {/*Ficha do animal*/}
                                <Grid item xs={12} sm={6}>
                                    <Accordion style={{minWidth:'100%'}} defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className={classes.heading}>Ficha Animal</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Table className={classes.table}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>Nome</TableCell>
                                                        <TableCell><b>{infoConsulta.nome}</b></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Data nascimento</TableCell>
                                                        <TableCell><b>{infoConsulta.dataNascimento}</b></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Especie</TableCell>
                                                        <TableCell><b>{infoConsulta.especie}</b></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Raça</TableCell>
                                                        <TableCell><b>{infoConsulta.raca}</b></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Microchip</TableCell>
                                                        <TableCell><b>{infoConsulta.microchip}</b></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Esterilizado</TableCell>
                                                        <TableCell><b>{infoConsulta.esterilizado==="nao"?"Não":"Sim"}</b></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Pós-cirugico</TableCell>
                                                        <TableCell><b>{infoConsulta.posCirurgico}</b></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2}>Obeservações</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2}><b>{infoConsulta.observacoes!==""?infoConsulta.observacoes:<Divider/>}</b></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2}><Button onClick={()=>setEditarPaciente(true)} color={"primary"} variant={"contained"} fullWidth={true}>Editar Paciente</Button></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                                {/*Documentos*/}
                                <Grid item xs={12} sm={6}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}

                                        >
                                            <Typography className={classes.heading}>Documentos</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12}>
                                                    <DropzoneArea
                                                        clearOnUnmount={false}
                                                        showPreviews={true}
                                                        showPreviewsInDropzone={false}
                                                        useChipsForPreview
                                                        onChange={files=>setReceitas(files)}
                                                        previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                                                        previewChipProps={{classes: { root: classes.previewChip } }}
                                                        dropzoneText={"Arrastar documentos ou clicar aqui."}
                                                        acceptedFiles={["application/pdf", "image/*"]}
                                                        filesLimit={10}
                                                        getFileLimitExceedMessage={filesLimit=>`O numero maximo de ficheiros é ${filesLimit}`}
                                                        getFileAddedMessage={fileName=> `${fileName} adicionado com sucesso`}
                                                        getFileRemovedMessage={fileName=> `${fileName} removido com sucesso`}
                                                        getDropRejectMessage={()=> `Ficheiro recusado`}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Button onClick={enviarFicheiros} disabled={receitas.length===0} variant={"contained"} color={"primary"}>Adicionar Documento</Button>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TableContainer component={Paper}>
                                                        <Table className={classes.table}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Documentos</TableCell>
                                                                    <TableCell></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {receitasMedicas.map((row, idx) => {
                                                                    return (
                                                                        <TableRow key={idx}>
                                                                            <TableCell><a rel="noreferrer" target={"_blank"} href={url+"receita/"+row.id+"/"+auth()}>{row.nomeDocumento}</a></TableCell>
                                                                            <TableCell align={"right"}><Button onClick={()=>removerReceita(row.id)}><DeleteForeverIcon/></Button></TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                {/*Historico do peso*/}
                                <Grid item xs={12} sm={6} style={{marginTop: 20}}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}


                                        >
                                            <Typography className={classes.heading}>Historico de Peso</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Data da consulta</TableCell>
                                                            <TableCell>Peso</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {/* eslint-disable-next-line array-callback-return */}
                                                        {infoConsulta.historicoPeso.map((row, idx) => {
                                                            if(row.peso!==null){
                                                                return (
                                                                    <TableRow key={idx}>
                                                                        <TableCell >{moment(row.dataInicio).format("DD/MM/YYYY")}</TableCell>
                                                                        <TableCell >{row.peso}KG</TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                {/*Material da consulta*/}
                                <Grid item xs={12} sm={6}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}

                                        >
                                            <Typography className={classes.heading}>Material utilizado</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12}>
                                                    <TableContainer component={Paper}>
                                                        <Table className={classes.table}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Artigo</TableCell>
                                                                    <TableCell>Quantidade</TableCell>
                                                                    <TableCell></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {material.map((row, idx) => {
                                                                    return (
                                                                        <TableRow key={idx}>
                                                                            <TableCell>{row.artigo.descricao}</TableCell>
                                                                            <TableCell>{row.quantidade}</TableCell>
                                                                            <TableCell align={"right"}><Button onClick={()=>removerMaterial(idx)}><DeleteForeverIcon/></Button></TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Select
                                                        onChange={(e)=>handleArtigo(e, 'artigo')}
                                                        name="artigos"
                                                        placeholder="Artigos"
                                                        options={artigos}
                                                        value={artigo.artigo.id!==""?artigo.artigo:null}
                                                        getOptionLabel ={(option)=> option.artigo}
                                                        getOptionValue ={(option)=>option.id}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField label={"Quantidade"} type={"number"} inputRef={quantidadeRef} onKeyPress={(e) => e.key === 'Enter' && adicionarMatrial()} value={artigo.quantidade} onChange={(e)=>handleArtigo(e, 'quantidade')} fullWidth={true}  variant={"outlined"}/>
                                                </Grid>
                                                <Grid item  xs={12} sm={3}>
                                                    <Button variant={"contained"} onClick={adicionarMatrial} color={"primary"}>Adicionar</Button>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                {/*Historico de vacinas*/}
                                <Grid item xs={12} sm={6} style={{marginTop: 20}}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}


                                        >
                                            <Typography className={classes.heading}>Historico de Vacinas</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Data da consulta</TableCell>
                                                            <TableCell>Vacinas</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {/* eslint-disable-next-line array-callback-return */}
                                                        {infoConsulta.historicoVacinas.map((row, idx) => {
                                                            if(row.vacina!==null){
                                                                return (
                                                                    <TableRow key={idx}>
                                                                        <TableCell >{moment(row.dataInicio).format("DD/MM/YYYY")}</TableCell>
                                                                        <TableCell >{row.vacina}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                {/*Vacinas*/}
                                <Grid item xs={12} sm={6}>
                                    <Accordion style={{marginTop: 20}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}

                                        >
                                            <Typography className={classes.heading}>Vacinas</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12}>
                                                    <TableContainer component={Paper}>
                                                        <Table className={classes.table}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Vacina</TableCell>
                                                                    <TableCell></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {vacinas.map((row, idx) => {
                                                                    return (
                                                                        <TableRow key={idx}>
                                                                            <TableCell>{row}</TableCell>
                                                                            <TableCell align={"right"}><Button onClick={()=>removerVacina(idx)}><DeleteForeverIcon/></Button></TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <TextField label={"Vacina"}  onKeyPress={(e) => e.key === 'Enter' && adicionarVacina()} value={artigoVacina} onChange={handleVacina} fullWidth={true} variant={"outlined"}/>
                                                </Grid>
                                                <Grid item  xs={12} sm={3}>
                                                    <Button variant={"contained"} onClick={adicionarVacina} color={"primary"}>Adicionar</Button>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                {/*Historico de consultas*/}
                                <Grid item xs={12} sm={6} style={{marginTop: 20}}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className={classes.heading}>Historico de consultas</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Data da consulta</TableCell>
                                                            <TableCell>Tipo de consulta</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {/* eslint-disable-next-line array-callback-return */}
                                                        {infoConsulta.historico.map((row, idx) => {
                                                            if(row.peso!==null){
                                                                return (
                                                                    <TableRow key={idx}>
                                                                        <TableCell colSpan={2}>
                                                                            <Accordion>
                                                                                <AccordionSummary
                                                                                    expandIcon={<ExpandMoreIcon />}
                                                                                >
                                                                                    <Table>
                                                                                        <TableBody>
                                                                                            <TableRow>
                                                                                                <TableCell colSpan={true}>{moment(row.dataInicio).format("DD/MM/YYYY")}</TableCell>
                                                                                                <TableCell colSpan={true}>{row.descricao}</TableCell>
                                                                                            </TableRow>
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails>
                                                                                    <Typography component={"component"}>
                                                                                        Relatório: {row.observacoes}
                                                                                    </Typography>

                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Button onClick={inserir} fullWidth variant={"contained"} color={"primary"}>Atualizar consulta</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
                <Dialog
                    open={editarPaciente}
                    onClose={()=>setEditarPaciente(false)}
                >
                    <DialogTitle id="alert-dialog-title">{"Editar Paciente"}</DialogTitle>
                    <DialogContent>
                        <PacienteEditar match={{params:{id: infoConsulta.animal_id}}} fn={status=>setEditarPaciente(status)} consulta={true} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setEditarPaciente(false)} color="primary">
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={fichaCliete}
                    onClose={()=>setFichaCliente(false)}
                >
                    <DialogContent>
                        <ClienteFicha match={{params:{id: infoConsulta.cliente_id}}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setEditarPaciente(false)} color="primary">
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={editarPaciente}
                    onClose={()=>setEditarPaciente(false)}
                >
                    <DialogTitle id="alert-dialog-title">{"Editar Paciente"}</DialogTitle>
                    <DialogContent>
                        <PacienteEditar match={{params:{id: infoConsulta.animal_id}}} fn={status=>setEditarPaciente(status)} consulta={true} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setEditarPaciente(false)} color="primary">
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={marcarConsulta}
                    onClose={()=>setMarcarConsulta(false)}
                >
                    <DialogTitle id="alert-dialog-title">{"Marcar consulta"}</DialogTitle>
                    <DialogContent >
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Container  component="main" maxWidth="sm">
                                <div style={{height:'100%'}}>
                                    <Grid spacing={2} container>
                                    <Grid item xs={6}>
                                        <KeyboardDateTimePicker
                                            variant="inline"
                                            format="DD/MM/YYYY HH:mm"
                                            margin="normal"
                                            openTo={"hours"}
                                            ampm={false}
                                            id="date-picker-inline"
                                            label="Data Inicio consulta"
                                            invalidDateMessage={"A data introduzida não é valida"}
                                            value={marcacao.start}
                                            onChange={(date)=>setMarcacao({
                                                start: date,
                                                end: moment(date).add(30, 'minutes'),
                                                open: marcacao.open,
                                                funcionario: marcacao.funcionario,
                                                animal: marcacao.animal,
                                                tipoConsulta: marcacao.tipoConsulta,
                                                info: marcacao.info
                                            })}

                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <KeyboardDateTimePicker
                                            variant="inline"
                                            format="DD/MM/YYYY HH:mm"
                                            margin="normal"
                                            openTo={"hours"}
                                            invalidDateMessage={"A data introduzida não é valida"}
                                            ampm={false}
                                            id="date-picker-inline"
                                            label="Data Fim consulta"
                                            minDate={moment(marcacao.start).add(5, 'minutes')}
                                            value={marcacao.end}
                                            onChange={(date)=>setMarcacao({
                                                start: marcacao.start,
                                                end: date,
                                                open: marcacao.open,
                                                funcionario: marcacao.funcionario,
                                                animal: marcacao.animal,
                                                tipoConsulta: marcacao.tipoConsulta,
                                                info: marcacao.info
                                            })}

                                        />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name="Funcionario"
                                                placeholder={"Funcionario"}
                                                options={funcionario}
                                                onChange={(op)=>setMarcacao({
                                                    start: marcacao.start,
                                                    end: marcacao.end,
                                                    open: marcacao.open,
                                                    funcionario: op.id,
                                                    animal: marcacao.animal,
                                                    tipoConsulta: marcacao.tipoConsulta,
                                                    info: marcacao.info
                                                })}
                                                menuPlacement="auto"
                                                maxMenuHeight={150}
                                                getOptionLabel={option => option.nome}
                                                getOptionValue={option => option.id}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name="Tipo consulta"
                                                placeholder={"Tipo de consulta"}
                                                options={tiposConsulta}
                                                menuPlacement="auto"
                                                maxMenuHeight={150}
                                                getOptionLabel={option => option.descricao}
                                                getOptionValue={option => option.id}
                                                onChange={(op)=>setMarcacao({
                                                    start: marcacao.start,
                                                    end: marcacao.end,
                                                    open: marcacao.open,
                                                    funcionario: marcacao.funcionario,
                                                    animal: marcacao.animal,
                                                    tipoConsulta: op.id,
                                                    info: marcacao.info
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button fullWidth={true} onClick={novaMarcacao} variant={"contained"} color={"primary"}>Agendar consulta</Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Container>
                        </MuiPickersUtilsProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setEditarPaciente(false)} color="primary">
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={fichaCliete}
                    onClose={()=>setFichaCliente(false)}
                >
                    <DialogContent>
                        <ClienteFicha match={{params:{id: infoConsulta.cliente_id}}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setEditarPaciente(false)} color="primary">
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </MuiPickersUtilsProvider>
    )
}