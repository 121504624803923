import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {TextField, Checkbox, Select } from 'unform-material-ui';
import {Form} from '@unform/web';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function ArtigosCriar() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({open:false, message:"", type:"info"});
    const [familias, setFamilias] = useState([]);
    const [unidades, setUnidades] = useState([]);

    useEffect(()=>{
        api.get('/familia/select').then(res=>{
            if(res.data.code===0){
                setFamilias(res.data.data);
            }
        })
        api.get('/item/UnitOfMeasure').then(res=>{
            if(res.data.code===0){
                setUnidades(res.data.data);
            }
        })
    }, [])


    async function criarArtigo(data){
        setOpen(true);
        if(data.descricao!==""){
            const res = await api.post('/item', data)
            if(res.data.code===0){
                setAlert({
                    open: true,
                    message: res.data.message,
                    type: 'success'
                })
            }else{
                setAlert({
                    open: true,
                    message: res.data.message,
                    type: 'error'
                })
            }
        }
        setOpen(false);
    }



    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Criar Artigos
                </Typography>
                <Form className={classes.form} onSubmit={criarArtigo} initialData={{estado:true, preco_1:'0.00',preco_2:'0.00',preco_3:'0.00',preco_4:'0.00',preco_5:'0.00' }} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="descricao"
                                label="Descrição do artigo"
                                name="descricao"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="itemCode"
                                variant="outlined"
                                required
                                fullWidth
                                id="itemCode"
                                label="Codigo do artigo"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Select name="unidade" variant="outlined" label="Uni. de venda do artigo">
                                {unidades.map(((value, index) => <MenuItem key={index} value={value.id} >{value.Description}</MenuItem>))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Select name="familia" label="Familias" variant="outlined">
                                {familias.map((value, index) => <MenuItem key={index} value={value.id} >{value.id+" - "+value.descricao}</MenuItem>
                                 )}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography  component="span" variant="subtitle1">
                                <center><b> Preço</b></center>
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Linha de preço</TableCell>
                                            <TableCell>Preços</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Cliente Comum</TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={"preco_1"}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    id=""
                                                    label="Preco"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Criador</TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={"preco_2"}
                                                    variant="outlined"
                                                    fullWidth
                                                    id=""
                                                    label="Preco"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Caçador</TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={"preco_3"}
                                                    variant="outlined"
                                                    fullWidth
                                                    id=""
                                                    label="Preco"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Protocolo</TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={"preco_4"}
                                                    variant="outlined"
                                                    fullWidth
                                                    id=""
                                                    label="Preco"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Gatil</TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={"preco_5"}
                                                    variant="outlined"
                                                    fullWidth
                                                    id=""
                                                    label="Preco"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="estado"
                                        color="primary"
                                    />
                                }
                                label="Disponivel"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="stock"
                                        color="primary"
                                    />
                                }
                                label="Stock"
                            />
                        </Grid>
                    </Grid>
                    <Collapse in={alert.open} >
                        <Alert severity={alert.type}>
                            {alert.message}
                        </Alert>
                    </Collapse>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Criar artigo
                    </Button>
                </Form>
            </div>
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}
