import React, {useEffect, useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../services/api";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 345,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Atualizacoes(props) {
    const classes = useStyles();
    const [data, setData] = useState("");

    useEffect(() => {
        api.get('/changelog').then(res => {
            setData(res.data)
        })
    }, [])

    return (
        <Container component="main" maxWidth="xl">
            <CssBaseline/>
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardContent>
                        {data!==""?<ReactMarkdown plugins={[gfm]} children={data}/>:null}
                    </CardContent>
                </Card>
            </div>
            <Backdrop className={classes.backdrop} open={false} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}
