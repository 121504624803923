import React, {useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import api, {auth, url} from '../../services/api'
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from "@material-ui/core/TableBody";
import {useHistory} from "react-router-dom"
import Swal from "sweetalert2";


const useStyles = makeStyles((theme) => ({
    root:{
        minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "35vw",

    }
}));

export default function ComprasCriar(props){
    const classes = useStyles();
    const [fornecedores, setFornecedores] = useState([]);
    const [artigos, setArtigos] = useState([]);
    const [tara, setTara] = useState({value:1, label:"Vendida"});
    const [contaCorrente, setContaCorrente] = useState(0);
    const [linhasArtigo, setLinhasArtigo] = useState([]);
    const [artigo, setArtigo] = useState([]);
    const [fornecedor, setFornecedor] = useState({
        "fornecedor": {
            "id": 0,
            "nome": "",
        },
        "cc": 0
    });
    const [totalDoc, setTotalDoc] = useState(0.00);
    const [pagamento, setPagamento] = useState(false);
    const [clienteDefenido, defineCliente] = useState(false);
    const [edit, setEdit] = useState(false);
    const history = useHistory();
    const [infoArtigo, setInfoArtigo] = useState({
        preco: Number().toFixed(2),
        id: "",
        itemCode: "",
        descricao: "",
        unidade: "",
        estado: "",
        precos:[0,0,0],
        idFamilia: "",
        familia:"",
        Description: "",
        MaximumDecimals: "0",
        UnitOfMeasureID: "",
        quantidade:"",
    });


    useEffect(()=>{
        api.get('/fornecedor').then(res=>{
            if(res.data.code===0){
                setFornecedores(res.data.data)
            }
        })
        api.get('/item').then(res=>{
            if(res.data.code===0){
                setArtigos(res.data.data)
            }
        })
    },[props]);

    function defenirLinhas() {
        const temp = linhasArtigo;
        let total=0.00;
        infoArtigo.tipo=tara.value;
        temp.push({
            preco: infoArtigo.preco,
            id: infoArtigo.id,
            itemCode: infoArtigo.itemCode,
            descricao: infoArtigo.descricao,
            unidade: infoArtigo.unidade,
            estado: infoArtigo.estado,
            precos:infoArtigo.precos,
            idFamilia: infoArtigo.idFamilia,
            familia: infoArtigo.familia,
            quantidade:Number(infoArtigo.quantidade).toFixed(parseInt(infoArtigo.MaximumDecimals)),
            tara: infoArtigo.tara,
            tipo:tara.value,
            Description:infoArtigo.Description,
            MaximumDecimals: infoArtigo.MaximumDecimals,
            UnitOfMeasureID: infoArtigo.UnitOfMeasureID,
        });

        for (let row of temp) {
            if(!isNaN(parseFloat(row.preco))) {
                total += (parseFloat(row.quantidade) * parseFloat(row.preco))
            }else{
                total+=0
            }
        }

        setTotalDoc(total);
        setInfoArtigo({
            preco: Number().toFixed(2),
            id: "",
            descricao: "",
            unidade: "",
            estado: "",
            idFamilia: "",
            familia:"",
            precos:[0,0,0],
            Description: "",
            MaximumDecimals: "0",
            UnitOfMeasureID: "",
            quantidade:"",
        })
        setArtigo({})
        setLinhasArtigo(temp)
    }

    function  defineContaCorrente(data) {
        setContaCorrente(data.cc)
        setFornecedor(data)
        defineCliente(true)
    }

    function defineArtigo(data) {
        setArtigo(data)
        data.quantidade = "";
        data.preco=Number().toFixed(2)
        setInfoArtigo(data);
        setTara({value:1, label:"Vendida"});
    }

    function definePreco(e) {
        if(!isNaN(Number(e.target.value))){
            if(Number(e.target.value)>=0) {
                setInfoArtigo({
                    preco: e.target.value,
                    id: infoArtigo.id,
                    descricao: infoArtigo.descricao,
                    unidade: infoArtigo.unidade,
                    estado: infoArtigo.estado,
                    idFamilia: infoArtigo.idFamilia,
                    familia: infoArtigo.familia,
                    quantidade: infoArtigo.quantidade,
                    tara: infoArtigo.tara,
                    precos: infoArtigo.precos,
                    Description:infoArtigo.Description,
                    MaximumDecimals: infoArtigo.MaximumDecimals,
                    UnitOfMeasureID: infoArtigo.UnitOfMeasureID,

                })
            }
        }
    }

    function defineQuantidade(e) {
        if(e.target.value>=0){
            setInfoArtigo({
                preco: infoArtigo.preco,
                id: infoArtigo.id,
                itemCode: infoArtigo.itemCode,
                descricao: infoArtigo.descricao,
                unidade: infoArtigo.unidade,
                estado: infoArtigo.estado,
                idFamilia: infoArtigo.idFamilia,
                familia: infoArtigo.familia,
                quantidade:e.target.value,
                tara: infoArtigo.tara,
                precos: infoArtigo.precos,
                Description:infoArtigo.Description,
                MaximumDecimals: infoArtigo.MaximumDecimals,
                UnitOfMeasureID: infoArtigo.UnitOfMeasureID,
            })
        }
    }

    async function guardarDoc(pago) {
        const data={};
        data.fornecedor=fornecedor.fornecedor.id;
        data.pago=pago
        data.linhas=linhasArtigo;
        const res =await api.post('/compra', data);
        setPagamento(false)
        if(res.data.code===0){
            await Swal.fire({
                title: "Informação",
                text: res.data.message,
                icon:"success",
                showDenyButton: true,
                showConfirmButton: false,
                //confirmButtonText:"Obter documento",
                denyButtonText:"Fechar",
            }).then(r=>{
                if(r.isConfirmed){
                    window.open(url+"pdf/docVenda/"+props.match.params.id+"/"+auth(), '_blank')
                    history.push("/compras/ver/"+res.data.data)

                }else{
                    history.push("/compras/ver/"+res.data.data)
                }
            })
        }else{
            await Swal.fire({
                title: "Informação",
                text: res.data.message,
                icon: "error",
                timer: 3000
            })
        }

    }

    function editarLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=linhasArtigo.filter((value, index) => {
            if(index===idx){
                setInfoArtigo(value);
                const listaTara=[{value:1, label:"Vendida"},{value:2, label:"Emprestada"},{value:3, label:"Oferta"} ];
                if(value.tipo!==undefined){
                    setTara(listaTara[value.tipo-1])
                }else{
                    setTara(listaTara[0])
                }

            }else{
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total+=(parseFloat(row.quantidade) * parseFloat(row.preco))
        }
        setTotalDoc(total)
        setLinhasArtigo(newTmp);
    }

    function removerLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=linhasArtigo.filter((value, index) => {
            if (index !== idx) {
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total+=(parseFloat(row.quantidade) * parseFloat(row.preco))
        }
        setTotalDoc(total)
        setLinhasArtigo(newTmp);
    }

    function removeZerosArtigo() {
        if(infoArtigo.preco===0 || infoArtigo.preco==="0.00"){
            setInfoArtigo({
                preco: "",
                id: infoArtigo.id,
                itemCode: infoArtigo.itemCode,
                descricao: infoArtigo.descricao,
                unidade: infoArtigo.unidade,
                estado: infoArtigo.estado,
                idFamilia: infoArtigo.idFamilia,
                familia:infoArtigo.familia,
                precos: infoArtigo.precos,
                quantidade:infoArtigo.quantidade,
                tara:infoArtigo.tara,
                Description:infoArtigo.Description,
                MaximumDecimals: infoArtigo.MaximumDecimals,
                UnitOfMeasureID: infoArtigo.UnitOfMeasureID,
            })
        }
    }

    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Registar Compra <br/>
                </Typography>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    onChange={defineContaCorrente}
                                    className={classes.select}
                                    name="fornecedor"
                                    placeholder="Fornecedores"
                                    options={fornecedores}
                                    option={fornecedor}
                                    getOptionLabel={(option)=> option.fornecedor.nome}
                                    getOptionValue ={(option)=>option.fornecedor.id}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        Conta corrente: <span style={contaCorrente>0?({color: "red"}):null}>{contaCorrente.toFixed(2)}€</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    onChange={defineArtigo}
                                    name="artigos"
                                    option={artigo}
                                    placeholder="Artigos"
                                    options={artigos}
                                    getOptionLabel={(option)=> {return option.itemCode + "  /  " + option.descricao}}
                                    getOptionValue ={(option)=>option.id}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TableContainer component={Paper} style={{marginBottom: 10}}>
                                    <Table className={classes.table} aria-label="Descricao do artigo">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><b>Preço</b></TableCell>
                                                <TableCell><b>Unidade</b></TableCell>
                                                <TableCell><b>Quantidade</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell><TextField name="preco" onClick={removeZerosArtigo} disabled={tara.value!==1} onChange={definePreco} type="number" value={infoArtigo.preco}/></TableCell>
                                                <TableCell>{infoArtigo.UnitOfMeasureID}</TableCell>
                                                <TableCell><TextField name="quantidade" onChange={defineQuantidade} type="number"  value={infoArtigo.quantidade}/></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button onClick={defenirLinhas} disabled={infoArtigo.itemCode === "" || parseFloat(infoArtigo.quantidade) <= 0 || infoArtigo.quantidade===""} fullWidth style={{marginTop: 20}} variant="contained" color="primary">Adicionar artigo</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="Artigos do documento">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><b>Descrição</b></TableCell>
                                                <TableCell ><b>Preço</b></TableCell>
                                                {edit===false?(<TableCell><b>Quantidade</b></TableCell>):(<TableCell colSpan={2}><center><b>Ações</b></center></TableCell>)}
                                                {edit===false?(<TableCell><b>o da linha</b></TableCell>):(null)}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {linhasArtigo.map((row, index) =>
                                                <TableRow key={index}>
                                                    <TableCell>{row.descricao}</TableCell>
                                                    <TableCell>{isNaN(parseFloat(row.preco))?"0.00":parseFloat(row.preco).toFixed(2)}€/{row.UnitOfMeasureID}</TableCell>
                                                    {edit===false?(<TableCell>{row.quantidade}</TableCell>):(<TableCell><Button onClick={()=>editarLinha(index)} variant="contained" color="primary">Editar linha</Button></TableCell>)}
                                                    {edit===false?(<TableCell>{isNaN(parseFloat(row.quantidade) * parseFloat(row.preco))?"0.00":(parseFloat(row.quantidade) * parseFloat(row.preco)).toFixed(2)}€</TableCell>):(<TableCell><Button variant="contained" color="secondary" className={classes.button} startIcon={<DeleteIcon />} onClick={() =>removerLinha(index)}>Remover linha</Button></TableCell>)}
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button onClick={()=>setPagamento(true)} disabled={linhasArtigo.length<= 0 || !clienteDefenido===true} fullWidth style={{marginTop: 20}} variant="contained" color="primary">Registar compra total: {totalDoc.toFixed(2)}€</Button>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={edit}
                                            onChange={()=>setEdit(!edit)}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Editar documento"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Dialog
                open={pagamento}
                onClose={()=>setPagamento(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Guardar documento?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        O documento dá no total {totalDoc.toFixed(2)}€ deseja colocar em conta corrente ou pagar agora?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setPagamento(false)} color="primary">
                        Cancelar ação
                    </Button>
                    <Button onClick={()=>guardarDoc(false)} disabled={totalDoc<=0} color="primary" autoFocus>
                        Conta corrente
                    </Button>
                    <Button onClick={()=>guardarDoc(true)} color="primary" autoFocus>
                        Pagamento agora
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
