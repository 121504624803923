import React, {useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import api, {auth, url} from '../../services/api'
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import dayjs from 'dayjs'
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Swal from "sweetalert2";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Alert, AlertTitle} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root:{
        minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "35vw",

    },
}));

export default function DocumentosVer(props){
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [doc, setDoc] = useState({
        cliente: "",
        linhas:[],
        pagamentos: [],
        informacoes:[
            {
                confirmado: 1,
                anulado:0
            }
        ]
    });
    const [total, setTotal] = useState(0);
    const [totalPago, setTotalPago] = useState(0);
    const [pagamento, setPagamento] = useState(false);
    const [valorPagamento, setValorPagamento] = useState(0);
    const [ultimo, setUltimo] = useState(0);
    const [anterior, setAnterior] = useState(false);

    // eslint-disable-next-line
    useEffect(()=>{
        setOpen(true)
        getInfo()
        // eslint-disable-next-line
    },[props.match.params.id]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function getInfo() {
        api.get('documentos/'+props.match.params.id).then(res=>{
            if(res.data.code===0){
                setDoc(res.data.data)
                let valorDoc=0;
                for(let row of res.data.data.linhas){
                    valorDoc+=row.totalLinha;
                }
                setTotalPago(res.data.data.totalPago)
                setTotal(valorDoc);
                setOpen(false)
            }
        })

        api.get('documentos/ultimo').then(res=>{
            if(res.data.code===0){
                setUltimo(res.data.data)
            }
        })

        api.get('documentos/'+(parseInt(props.match.params.id)-1)).then(res=>{
            if(res.data.code===-10){
                setAnterior(true)
            }else{
                setAnterior(false)
            }
        })
    }

    function pagar(){
        setPagamento(false)
        api.post('documentos/Pagamentos',{
            valor:valorPagamento,
            documento:props.match.params.id
        }).then(async res=>{
            getInfo()
            await Swal.fire({
                title: "Informação",
                text: res.data.message,
                icon: res.data.code === 0 ? "success" : "error",
                timer: 3000
            })
        })
    }

    function calculatePrice(quantidade, preco, desconto=0){
        const precoFinal = parseFloat(quantidade)*parseFloat(preco);
        return precoFinal - (precoFinal*(parseFloat(desconto)/100))
    }


    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={classes.paper}>
                <Grid container spacing={3} style={{marginBottom:10}}>
                    <Grid item xs={3} sm={3}>
                        <Link to={`/documentos/ver/`+(parseInt(props.match.params.id)-1)} onClick={(e)=>anterior===true?e.preventDefault():null}>
                            <Button
                                disabled={anterior}
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                startIcon={<ArrowBackIosIcon />}
                            />
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Typography component="h1" variant="h5">
                            Reg. Nº {props.match.params.id}<br/>
                        </Typography>
                        <Typography component="h1" variant="h5">
                            Confirmada {doc.informacoes[0].confirmado==2?"Sim":"Não"}<br/>
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Link to={`/documentos/ver/`+(parseInt(props.match.params.id)+1)} onClick={(e)=>parseInt(props.match.params.id)+1===parseInt(ultimo+1)?e.preventDefault():null}>
                            <Button
                                disabled={parseInt(props.match.params.id)+1===ultimo+1}
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                endIcon={<ArrowForwardIosIcon />}
                            />
                        </Link>
                    </Grid>
                </Grid>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h5" component="p">
                                    Cliente: {doc.cliente}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="Artigos do documento">
                                        <TableHead>
                                            <TableRow>
                                               <TableCell><b>Codigo artigo</b></TableCell>
                                                <TableCell><b>Descrição</b></TableCell>
                                                <TableCell ><b>Preço</b></TableCell>
                                                <TableCell><b>Quantidade</b></TableCell>
                                                <TableCell><b>Desconto</b></TableCell>
                                                <TableCell><b>Preço da linha</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {doc.linhas.map((row, index) =>
                                                <TableRow key={index}>
                                                    <TableCell>{row.itemCode}</TableCell>
                                                    <TableCell>{row.descricao}</TableCell>
                                                    <TableCell>{parseFloat(row.preco).toFixed(2)}€/{row.UnitOfMeasureID}</TableCell>
                                                    <TableCell>{Number(row.quantidade).toFixed(row.MaximumDecimals)}</TableCell>
                                                    <TableCell>{parseFloat(row.desconto).toFixed(2)}</TableCell>
                                                    <TableCell>{calculatePrice(parseFloat(row.quantidade), parseFloat(row.preco), parseFloat(row.desconto).toFixed(2)).toFixed(2)}€</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Typography variant="h5" component="p" style={{margin:20}}>
                                    Historico de pagamentos do documento
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="Artigos do documento">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><b>Valor</b></TableCell>
                                                <TableCell><b>Data</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {doc.pagamentos.map((row, index) =>
                                                <TableRow key={index}>
                                                    <TableCell>{parseFloat(row.valor).toFixed(2)}€</TableCell>
                                                    <TableCell>{dayjs(row.created_at).format("DD-MM-YYYY")}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {
                                        doc.informacoes[0].anulado == 1 ? (
                                            <Alert severity="error">
                                                <AlertTitle><center>O documento foi anulado</center></AlertTitle>
                                            </Alert>
                                        ) : (<>
                                            <Typography variant="h5" component="p" style={{marginTop: 20}}>
                                                Valor documento: {total.toFixed(2)}€
                                            </Typography>
                                    <Typography variant="h5" component="p" style={{marginTop:20}}>
                                    Resumo do documento: {totalPago<total.toFixed(2)?(<>Falta: {(total-totalPago).toFixed(2)}€</>):(<>Documento pago</>)}
                                    </Typography>
                                            </>
                                    )
                                }
                            </Grid>
                            {doc.informacoes[0].anulado == 0?(
                                <>
                                        <Grid item xs={12} sm={6}>
                                            <Link to={"/documentos/editar/"+props.match.params.id}><Button variant="contained" color="primary" fullWidth={true}>Editar</Button></Link>
                                        </Grid>
                                    {
                                        totalPago<total.toFixed(2)?(
                                        <Grid item xs={12} sm={6}>
                                            <Button variant="contained" onClick={()=>setPagamento(true)} fullWidth={true}>Realizar pagamento</Button>
                                        </Grid>):null
                                }
                                    <Grid item xs={12} sm={12}>
                                    <a target={"_blank"} rel="noopener noreferrer" href={url+"pdf/docVenda/"+props.match.params.id+"/"+auth()}><Button variant="contained" color="primary" fullWidth={true}>Obter documento</Button></a>
                                    </Grid>
                                </>
                            ):null}


                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Dialog open={pagamento} onClose={()=>setPagamento(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Realizar pagamento</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                       O documento já tem pago {totalPago.toFixed(2)}€ e falta {(total-totalPago).toFixed(2)}€
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        value={valorPagamento}
                        onChange={e=>{
                            const numero = e.target.value;
                            if (Number(numero) <= (total-totalPago) && Number(numero) >= 0) {
                                    setValorPagamento(numero)
                            }
                        }}
                        label="Valor a pagar"
                        fullWidth
                    />
                    <Button variant={"contained"} style={{marginTop:10}} fullWidth={true} onClick={()=>setValorPagamento((total-totalPago).toFixed(2))} color="primary">
                        Colocar valor total
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setPagamento(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={()=>pagar()} color="primary">
                        Pagar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
