import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import {ListItem, SwipeableDrawer, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));




export default function ConsultasListar() {
    const classes = useStyles();
    const theme = useTheme();

    const [consultas, setConsultas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filtro, setFiltro] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [options, setOptions] = useState({
        nomeCliente: false,
        nomeAnimal: false,
        data:false
    })

    const [q, setQ]=useState("");

    useEffect(()=>{
        obter();
        // eslint-disable-next-line
    }, [])

    function obter(page=currentPage, size=perPage, op=options, query=q){
        setLoading(true)
        api.post(`/consulta?page=${page}&per_page=${size}&q=${query}`, op).then(res=>{
            if(res.data.code===0){
                setConsultas(res.data.data.rows)
                setTotalRows(res.data.data.total)
            }
            setLoading(false)
        })
    }

    function pesquisa(e=null, op=options) {
        obter(currentPage, perPage, op, q)
    }

    function handleTextChange(e){
        setQ(e.target.value)
    }

    const handlePageChange = page => {
        obter(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        obter(page, newPerPage);
        setPerPage(newPerPage);
    };

    const columns = [
        {
            name: "Cliente",
            selector: "nomeCliente",
            sortable: true
        },
        {
            name:"Animal",
            selector: "nomeAnimal",
            sortable: true
        },
        {
            name:"Data",
            selector: 'data',
            sortable: true
        },
        {
            name: "Ver",
            cell: row=><Link to={"/consulta/"+row.id}><Button variant={"contained"} color={"primary"}>Aceder a consulta</Button></Link>
        }
    ];



    return (
        <Container component="main" maxWidth="md">
            <div className={classes.paper}>
                <DataTable
                    title="Consultas"
                    columns={columns}
                    data={consultas}
                    sortIcon={<SortIcon />}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationDefaultPage={currentPage}
                    actions={
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={()=>setFiltro(!filtro)}
                                endIcon={<SearchIcon>send</SearchIcon>}
                            >
                                Filtros
                            </Button>
                        </>
                    }
                    selectableRowsComponent={Checkbox}
                    contextMessage={{
                        singular: 'artigo',
                        plural: 'artigos',
                        message: 'selecionada\\s' }}
                    noDataComponent={"Não foi encontrados clientes"}
                    progressPending={loading}
                    progressComponent={<CircularProgress />}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Linhas por pagina:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'Todas'
                    }}
                />
                <SwipeableDrawer
                    anchor={'right'}
                    Backdrop={false}
                    variant="persistent"
                    BackdropProps={{ invisible: true }}
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    open={filtro}
                    onClose={()=>setFiltro(false)}
                    onOpen={()=>setFiltro(true)}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={()=>setFiltro(!filtro)}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <List>
                        <ListItem style={{"text-align": "center"}}>
                            <Typography variant="h6" noWrap>
                                Filtros
                            </Typography>
                        </ListItem>
                        <Divider />
                        <ListItem >
                            <TextField value={q} label="Pesquisa" onChange={handleTextChange}/>
                        </ListItem>
                        <Divider/>
                        <ListItem button onClick={()=> {
                            if(options.nomeCliente===true && options.nomeAnimal===true && options.data===true){
                                setOptions({
                                    nomeCliente:false,
                                    nomeAnimal:false,
                                    data:false
                                })
                            }else{
                                setOptions({
                                    nomeCliente:true,
                                    nomeAnimal:true,
                                    data:true
                                })
                            }
                        }}>
                            <Switch
                                checked={options.nomeCliente===true && options.nomeAnimal===true && options.data===true}
                                name="checkedB"
                                color="primary"
                            />
                            <span>Todos</span>
                        </ListItem>
                        <Divider/>

                        <Tooltip title="O filtro usa a data de incio da consulta, data de fim e data de criação da marcação" arrow>
                        <ListItem className={""} button onClick={()=> {
                            setOptions({
                                nomeCliente: options.nomeCliente,
                                nomeAnimal: options.nomeAnimal,
                                data: !options.data
                            })
                        }}>

                            <Switch
                                checked={options.data}
                                name="checkedB"
                                color="primary"
                            />
                            <span>Filtrar por data</span>
                        </ListItem>
                        </Tooltip>

                        <ListItem button onClick={() =>{
                            setOptions({
                                nomeCliente: options.nomeCliente,
                                nomeAnimal: !options.nomeAnimal,
                                data: options.data
                            })
                        }}>
                            <Switch
                                checked={options.nomeAnimal}
                                name="checkedB"
                                color="primary"
                            />
                            <span>Filtrar pelo nome do animal</span>
                        </ListItem>

                        <ListItem button onClick={() =>{
                            setOptions({
                                nomeCliente: !options.nomeCliente,
                                nomeAnimal: options.nomeAnimal,
                                data: options.data
                            })
                        }}>
                            <Switch
                                checked={options.nomeCliente}
                                name="checkedB"
                                color="primary"
                            />
                            <span>Filtrar pelo numero de cliente</span>
                        </ListItem>
                        <ListItem><Button onClick={()=>pesquisa()} fullWidth={true} color={"primary"} variant={"contained"}>Filtrar</Button></ListItem>
                    </List>
                </SwipeableDrawer>
            </div>
        </Container>
    );
}
