import React, {useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import api from '../../services/api'
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from "@material-ui/core/TableBody";
import {useHistory} from "react-router-dom"
import {useSnackbar} from "notistack";



const useStyles = makeStyles((theme) => ({
    root:{
        minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "35vw",
    }
}));

export default function ComprasEditar(props){
    const classes = useStyles();
    const [artigos, setArtigos] = useState([]);
    const [tara, setTara] = useState({value:1, label:"Vendida"});
    const { enqueueSnackbar } = useSnackbar();
    const [infoArtigo, setInfoArtigo] = useState({
        preco: Number().toFixed(2),
        id: "",
        descricao: "",
        unidade: "",
        estado: "",
        idFamilia: "",
        familia:"",
        quantidade:Number().toFixed(2),
    });
    const [artigo, setArtigo] = useState([]);
    const [totalDoc, setTotalDoc] = useState(0.00);
    const [pagamento, setPagamento] = useState(false);
    const [edit, setEdit] = useState(false);
    const history = useHistory();
    const [documento, setDocumento] = useState({
        cc:Number(),
        fornecedor: "",
        informacoes: [],
        linhas: [],
        pagamentos: [],
        informacoesCliente:[{
            linhaPrecos:1
        }]
    });

    useEffect(()=>{
        api.get('/compra/'+props.match.params.id).then(res=>{
            if(res.data.code===0){
                setDocumento(res.data.data);
                let temp = 0;
                for (let row of res.data.data.linhas){
                    temp += row.totalLinha

                }
                console.log(res.data.data)
                setTotalDoc(temp)
            }
        })
        api.get('/item').then(res=>{
            if(res.data.code===0){
                setArtigos(res.data.data)
            }
        })
    },[props.match.params.id]);

    function defenirLinhas() {
        const temp = documento;
        let total=0.00;
        temp.linhas.push({
            preco: infoArtigo.preco,
            id: infoArtigo.id,
            descricao: infoArtigo.descricao,
            unidade: infoArtigo.unidade,
            estado: infoArtigo.estado,
            idFamilia: infoArtigo.idFamilia,
            familia: infoArtigo.familia,
            quantidade:Number(infoArtigo.quantidade).toFixed(parseInt(infoArtigo.MaximumDecimals)),
            tara: infoArtigo.tara,
            tipo:tara.value,
            Description:infoArtigo.Description,
            MaximumDecimals: infoArtigo.MaximumDecimals,
            UnitOfMeasureID: infoArtigo.UnitOfMeasureID,
        });
        for (let row of temp.linhas) {
            if(!isNaN(parseFloat(row.preco))) {
                total += (parseFloat(row.quantidade) * parseFloat(row.preco))
            }else{
                total+=0
            }
        }

        setTotalDoc(total);
        setInfoArtigo({
            preco: Number().toFixed(2),
            id: "",
            descricao: "",
            unidade: "",
            estado: "",
            idFamilia: "",
            familia:"",
            precos:[0,0,0],
            Description: "",
            MaximumDecimals: "0",
            UnitOfMeasureID: "",
            quantidade:Number().toFixed(parseInt('0')),
        })
        setArtigo({})

        setDocumento(temp)
    }

    function defineArtigo(data) {
        data.preco=""
        setArtigo(data)
        data.quantidade = "";
        setInfoArtigo(data);
        setTara({value:1, label:"Vendida"});
    }

    function definePreco(e) {
        if(e.target.value>=0) {
            setInfoArtigo({
                preco: e.target.value,
                id: infoArtigo.id,
                descricao: infoArtigo.descricao,
                unidade: infoArtigo.unidade,
                estado: infoArtigo.estado,
                idFamilia: infoArtigo.idFamilia,
                familia: infoArtigo.familia,
                quantidade: infoArtigo.quantidade,
                tara: infoArtigo.tara,
                Description:infoArtigo.Description,
                MaximumDecimals: infoArtigo.MaximumDecimals,
                UnitOfMeasureID: infoArtigo.UnitOfMeasureID,

            })
        }
    }

    function defineQuantidade(e) {
        if(e.target.value>=0){
            setInfoArtigo({
                preco: infoArtigo.preco,
                id: infoArtigo.id,
                descricao: infoArtigo.descricao,
                unidade: infoArtigo.unidade,
                estado: infoArtigo.estado,
                idFamilia: infoArtigo.idFamilia,
                familia: infoArtigo.familia,
                quantidade:e.target.value,
                tara: infoArtigo.tara,
                Description:infoArtigo.Description,
                MaximumDecimals: infoArtigo.MaximumDecimals,
                UnitOfMeasureID: infoArtigo.UnitOfMeasureID,

            })
        }
    }

    function guardarDoc() {
        api.put('/compra/'+props.match.params.id, documento).then(async res=>{
            setPagamento(false)
            enqueueSnackbar(res.data.message, { variant: res.data.code === 0 ? "success" : "error", autoHideDuration: 1500 });

            if(res.data.code === 0) {
                history.push("/compras/listar/pagas")
            }
        })
    }

    function editarLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=documento.linhas.filter((value, index) => {
            if(index===idx){
                console.log(value)
                setInfoArtigo(value);
            }else{
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total+=(parseFloat(row.quantidade) * parseFloat(row.preco))
        }
        setTotalDoc(total)
        let temp = documento
        temp.linhas = newTmp
        setDocumento(temp);
    }

    function removerLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=documento.linhas.filter((value, index) => {
            if (index !== idx) {
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total+=(parseFloat(row.quantidade) * parseFloat(row.preco))
        }
        setTotalDoc(total)
        let temp = documento
        temp.linhas = newTmp
        setDocumento(temp);

    }

    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Editar compra <br/>
                </Typography>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    onChange={defineArtigo}
                                    name="artigos"
                                    option={artigo}
                                    placeholder="Artigos"
                                    options={artigos}
                                    getOptionLabel={(option)=> {return option.descricao}}
                                    getOptionValue ={(option)=>option.id}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TableContainer component={Paper} style={{marginBottom: 10, marginTop: 20}}>
                                    <Table className={classes.table} aria-label="Descricao do artigo">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Preço</TableCell>
                                                <TableCell>Unidade</TableCell>
                                                <TableCell>Quantidade</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell><TextField name="preco" disabled={tara.value!==1} onChange={definePreco} type="number" value={infoArtigo.preco}/></TableCell>
                                                <TableCell>{infoArtigo.UnitOfMeasureID}</TableCell>
                                                <TableCell><TextField name="quantidade" onChange={defineQuantidade} type="number"  value={infoArtigo.quantidade}/></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button onClick={defenirLinhas} disabled={parseFloat(infoArtigo.quantidade) <= 0} fullWidth style={{marginTop: 20}} variant="contained" color="primary">Adicionar artigo</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="Artigos do documento">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Descrição</TableCell>
                                                <TableCell >Preço</TableCell>
                                                {edit===false?(<TableCell>Quantidade</TableCell>):(<TableCell colSpan={2}><center>Ações</center></TableCell>)}
                                                {edit===false?(<TableCell>Preço da linha</TableCell>):(null)}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {documento.linhas.map((row, index) =>
                                                <TableRow key={index}>
                                                    <TableCell>{row.descricao}</TableCell>
                                                    <TableCell>{isNaN(parseFloat(row.preco))?"0.00":parseFloat(row.preco).toFixed(2)}€/{row.UnitOfMeasureID}</TableCell>
                                                    {edit===false?(<TableCell>{Number(row.quantidade).toFixed(row.MaximumDecimals)}</TableCell>):(<TableCell><Button onClick={()=>editarLinha(index)} variant="contained" color="primary">Editar linha</Button></TableCell>)}
                                                    {edit===false?(<TableCell>{isNaN(parseFloat(row.quantidade) * parseFloat(row.preco))?"0.00":(parseFloat(row.quantidade) * parseFloat(row.preco)).toFixed(2)}€</TableCell>):(<TableCell><Button variant="contained" className={classes.button} startIcon={<DeleteIcon />} onClick={() =>removerLinha(index)}>Remover linha</Button></TableCell>)}
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button onClick={()=>setPagamento(true)} disabled={documento.linhas.length<= 0 } fullWidth style={{marginTop: 20}} variant="contained" color="primary">Guardar documento total: {totalDoc.toFixed(2)}€</Button>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={edit}
                                            onChange={()=>setEdit(!edit)}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Editar documento"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Dialog
                open={pagamento}
                onClose={()=>setPagamento(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Guardar documento?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        O documento dá no total {totalDoc.toFixed(2)}€ guardar agora?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setPagamento(false)} color="primary">
                        Cancelar ação
                    </Button>
                    <Button onClick={()=>guardarDoc()} color="primary" autoFocus>
                        Guardar alterações
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
