import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "react-router-dom"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItem,
    ListItemText
} from "@material-ui/core";
import FlatList from 'flatlist-react';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 345,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function ArtigoFicha(props) {
    const classes = useStyles();
    const [open, ] = useState(false);
    const [rastreabilidade, setRastreabilidade] = useState(true);
    const [historico, setHistorico] = useState({
        data:[],
        current_page:0
    })
    const [hasMore, setHasmore] = useState(true)
    const [load, setLoad] = useState(true)

    const [data, setData] = useState({
        Description: "",
        MaximumDecimals: "",
        UnitOfMeasureID: "",
        descricao: "",
        familia: "",
        id: 7,
        idFamilia: "",
        itemCode: "",
        precos: [0, 0, 0],
        tara: 0,
    });

    useEffect(()=>{
        api.get('/item/'+props.match.params.id).then(res=>{
            setData(res.data.data)
        })
    }, [props.match.params.id])

    useEffect(()=>{
        if(load){
            setLoad(false)
            api.get('/traceability/'+props.match.params.id+'?page='+(parseInt(historico.current_page)+1)).then(res=>{
                setHistorico(res.data)
            })
        }

    }, [load])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Artigo: "+data.descricao}
                        titleTypographyProps={{ align: 'center' }}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography  component="h4" variant="h5"><center>Informações:</center></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Familia</b> <br/><Link to={"/familias/"+data.idFamilia}><u>{data.familia}</u></Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Código artigo</b> <br/>{data.itemCode}
                                </Typography>
                            </Grid>
                            {data.stock?(
                                <Grid item xs={12} sm={6}>
                                    <Typography  component="span" variant="subtitle1">
                                        <b>Disponível</b> <br/>{data.disponivel}
                                    </Typography>
                                </Grid>
                            ):null}
                            <Grid item xs={12} sm={6}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Unidade de venda</b> <br/>{data.Description}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography  component="span" variant="subtitle1">
                                    <center><b> Preço</b></center>
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Linha de preço</TableCell>
                                                <TableCell>Preços</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.precos.map((value, index) => (
                                                <TableRow>
                                                    <TableCell>{(index+1)===1?"Comum":(index+1)===2?"Criador":(index+1)===3?"Caçador":(index+1)===4?"Protocolo":"Gatil"}</TableCell>
                                                    <TableCell>{value}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography component="h4" variant="h5"><center>Ações:</center></Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Link to={"/artigos/editar/" + data.id}><Button fullWidth={true} variant={"contained"} color={"primary"}>Editar</Button></Link>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {data.stock?<Button fullWidth={true} variant={"contained"} color={"primary"}>Rastreabilidade</Button>:null}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                open={rastreabilidade}
                //onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Rastreabilidade do artigo"}</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Quantidade</TableCell>
                                    <TableCell>Data</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <FlatList
                                list={historico.data}
                                paginationLoadingIndicator={<div style={{width:'100%', textAlign:'center'}}><CircularProgress /></div>}
                                renderWhenEmpty={() =>
                                    <ListItem
                                    >
                                        <ListItemText primary={"Não existe histórico de movimentos do artigo."}/>
                                    </ListItem>}
                                renderItem={row=>(
                                    <TableRow key={row.id}>
                                        <TableCell>{row.tipo==0?"Venda":"Compra"}</TableCell>
                                        <TableCell>{Math.abs(row.quantidade)}</TableCell>
                                        <TableCell>{moment(row.created_at).format("YYYY-MM-DD")}</TableCell>
                                    </TableRow>
                                )}
                            />
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setRastreabilidade(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
