import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {TextField} from 'unform-material-ui';
import {Form} from '@unform/web';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSnackbar} from "notistack";
import { useHistory } from "react-router-dom";
import ColorPicker from "material-ui-color-picker";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function EstadosCriar(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [cor, setCor] = useState("#000");
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();


    async function registarEstado(data){
        setOpen(true);
        if(cor !== undefined){
            data.cor=cor;
            const res = await api.post('/estados', data)
            if(res.data.code===0){
                enqueueSnackbar(res.data.message, { variant: "success", autoHideDuration: 1500 });
                history.push("/estados");
            }else{
                enqueueSnackbar(res.data.message, { variant: "error", autoHideDuration: 2500 });

            }
        }
        setOpen(false);
    }

    return (
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Estados do paciente
                    </Typography>
                    <Form className={classes.form} onSubmit={registarEstado} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    label="Descrição"
                                    name="descricao"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <ColorPicker
                                    required
                                    fullWidth
                                    name="cor"
                                    value={cor}
                                    label={"Cor"}
                                    inputProps={{value:cor}}
                                    onChange={val=>setCor(val)}/>
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Registar estado
                        </Button>
                    </Form>
                </div>
                <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
    );
}
