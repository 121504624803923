import {bindHover, usePopupState} from "material-ui-popup-state/hooks";
import {useHistory} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileMedical} from "@fortawesome/fontawesome-free-solid";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import {bindMenu} from "material-ui-popup-state/es/core";
import MenuItem from "@material-ui/core/MenuItem";
import {Divider} from "@material-ui/core";
import React from "react";

export default function MenuConsulta(){
    const popupState = usePopupState({ variant: 'popover', popupId: 'Consultas' })
    const history = useHistory();

    return(
        <>
            <center>
                <IconButton  variant="contained" color="primary" {...bindHover(popupState)}>
                    <FontAwesomeIcon color={"#fff"} size={'1x'} icon={faFileMedical}/>
                </IconButton >
                <Typography style={{color: '#fff'}} >Consultas</Typography>
            </center>
            <Menu
                {...bindMenu(popupState)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <MenuItem onClick={()=>{
                    popupState.close()
                    history.push("/consultas")
                }}>Todas as consultas
                </MenuItem>
                <Divider/>
                <MenuItem onClick={()=>{
                    popupState.close()
                    history.push("/consultas/proximas")
                }}>Diario de consultas
                </MenuItem>
            </Menu>
        </>
    )
}