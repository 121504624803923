import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Checkbox, TextField, Select} from 'unform-material-ui';
import {Form} from '@unform/web';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import {useSnackbar} from "notistack";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function ClienteCriar() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    async function criarCliente(data){
        setOpen(true);
        if(data.descricao!==""){
            const res = await api.post('/cliente', data)
            if(res.data.code===0){
                enqueueSnackbar(res.data.message, { variant: "success", autoHideDuration: 1500 });
            }else{
                enqueueSnackbar(res.data.message, { variant: "error", autoHideDuration: 2500 });

            }
        }
        setOpen(false);
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Ficha de Cliente
                    </Typography>
                    <Form className={classes.form} onSubmit={criarCliente} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    label="Nome"
                                    name="nome"
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Morada"
                                    name="morada"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Codigo postal"
                                    name="codigoPostal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Distrito"
                                    name="distrito"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Concelho"
                                    name="concelho"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Nif"
                                    name="nif"
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Telemovel"
                                    name="telemovel"
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Email"
                                    name="email"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} style={{width:'25%'}} >
                                <Select name="linhaPrecos" defaultValue={1} variant="outlined" required label="Tabela de precos">
                                    <MenuItem value={1} >Cliente Comum</MenuItem>
                                    <MenuItem value={2} >Criador</MenuItem>
                                    <MenuItem value={3} >Caçador</MenuItem>
                                    <MenuItem value={4} >Protocolo</MenuItem>
                                    <MenuItem value={5} >Gatil</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography>Observações:</Typography>
                                <TextField
                                    variant="filled"
                                    fullWidth
                                    name="observacoes"
                                    multiline
                                    rows={2}
                                    rowsMax={4}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="estado"
                                            color="primary"
                                        />
                                    }
                                    label="Inativo"
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Criar cliente
                        </Button>
                    </Form>
                </div>
                <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </MuiPickersUtilsProvider>
    );
}
