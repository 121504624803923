import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";

import {Link} from "react-router-dom"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Divider, Paper} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '75hw',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table:{
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderButtonColor: theme.palette.main
    },
    divisor:{
        background: theme.palette.primary.main
    },
    separator:{
        borderRightStyle: "solid",
        borderWidth: 1,
        borderRightColor: theme.palette.primary.main
    },
    separatorCampos:{
        borderRightStyle: "solid",
        borderWidth: 1,
        borderRightColor: theme.palette.primary.main,
        borderButtonColor: theme.palette.main,
        borderBottomColor:theme.palette.primary.main ,
    },
    separatorCamposBottom:{
        borderWidth: 1,
        borderButtonColor: theme.palette.main,
        borderBottomColor:theme.palette.primary.main ,
    },
    header:{
        borderRightStyle: "solid",
        borderLeftStyle: "solid",
        borderTopStyle: "solid",
        borderBottomStyle: "solid",
        borderWidth: 1,
        borderRightColor: theme.palette.primary.main,
        borderLeftColor: theme.palette.primary.main,
        borderTopColor:theme.palette.primary.main ,
        borderBottomColor:theme.palette.primary.main ,
    },
}));

export default function ClienteFicha(props) {
    const classes = useStyles();
    const [data, setData] = useState({});
    const [animais, setAnimais] = useState([]);
    const [consultas, setConsultas] = useState([]);
    const [contaCorrente, setContaCorrente] = useState(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [showAnimalList, setShowAnimalList] = React.useState(false);

    useEffect(()=>{
        if(props.match.params.id!==undefined){
            api.get('/cliente/'+props.match.params.id).then(res=>{
                setData(res.data.data.data)
                setContaCorrente(res.data.data.contaCorrente)
                setConsultas(res.data.data.consultas)
                setAnimais(res.data.data.animais)
            })
        }
    }, [props.match.params.id])

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Ficha de Cliente"}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} >
                                <Typography component="span" variant="subtitle1">
                                    <b>Nome:</b> <br/>{data.nome}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={7}  className={classes.separator}  >
                                <Typography  component="span" variant="subtitle1">
                                    <b>Morada:</b> <br/>{data.morada}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Distrito:</b> <br/>{data.distrito}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={8}  className={classes.separator}  >
                                <Typography  component="span" variant="subtitle1">
                                    <b>Concelho:</b> <br/>{data.concelho}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Codigo Postal:</b>  <br/>{data.codigoPostal}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={4}  className={classes.separator}  >
                                <Typography  component="span" variant="subtitle1">
                                    <b>Contribuinte:</b> <br/>{data.nif}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}  className={classes.separator}  >
                                <Typography  component="span" variant="subtitle1">
                                    <b>E-mail:</b> <br/><a href={"mailto:"+data.email}>{data.email}</a>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Telemóvel:</b> <br/>{data.telemovel}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={6}  className={classes.separator}  >
                                <Typography style={contaCorrente>0?({
                                    color:'red'
                                }):null}  component="span" variant="subtitle1">
                                    <b>Conta corrente:</b> <br/> {contaCorrente.toFixed(2)}€
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Linha de preço</b> <br/>{data.linhaPrecos==1?"Preço comum":data.linhaPrecos==2?"Preço criador":"Preço caçador"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                                <br/>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography  component="h4" variant="h5">Historico do Cliente</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell className={classes.header} >Consulta (data)</TableCell>
                                                <TableCell className={classes.header} >Paciente</TableCell>
                                                <TableCell className={classes.header} >Aceder consulta</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                consultas.map((row, idx)=>(
                                                    <TableRow key={idx} >
                                                        <TableCell className={classes.separatorCampos}>{moment(row.dataInicio).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell className={classes.separatorCampos}>{row.nome}</TableCell>
                                                        <TableCell className={classes.separatorCamposBottom}><Link to={"/consulta/"+row.counsulta_id}><Button variant={"contained"} color={"primary"}>Aceder consulta</Button></Link></TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography  component="h4" variant="h5">Observações</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography>{data.observacoes}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography  component="h4" variant="h5"><center>Ações:</center></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button fullWidth={true} onClick={() => setShowAnimalList(true)} type="button" color="primary">Ver Pacientes</Button>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Link  to={"/clientes/editar/" + props.match.params.id}><Button fullWidth={true} variant={"contained"} color={"primary"}>Editar</Button></Link>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Dialog
                fullScreen={fullScreen}
                open={showAnimalList}
                onClose={()=>setShowAnimalList(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Lista de pacientes"}</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.header}>Nome</TableCell>
                                    <TableCell className={classes.header}>Microship</TableCell>
                                    <TableCell className={classes.header}>Ver</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {animais.map((item, idx)=>{
                                    return(
                                        <TableRow key={idx} >
                                            <TableCell className={classes.separatorCampos}>{item.nome}</TableCell>
                                            <TableCell className={classes.separatorCampos}>{item.microchip===""?"--":item.microchip}</TableCell>
                                            <TableCell className={classes.separatorCamposBottom}><Link to={'/paciente/'+item.id}><Button color="primary" variant="contained">Ver</Button></Link></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setShowAnimalList(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
