import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";

import {Link} from "react-router-dom"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Checkbox, Divider, FormControlLabel, Paper} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMars, faVenus} from "@fortawesome/free-solid-svg-icons";


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '75hw',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table:{
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderButtonColor: theme.palette.main
    },
    divisor:{
        background: theme.palette.primary.main
    },
    divisorVazio:{
        background: theme.palette.primary.main,
        marginLeft: 50,
        marginRight: 50,
        marginTop: 10,
    },
    separator:{
        borderRightStyle: "solid",
        borderWidth: 1,
        borderRightColor: theme.palette.primary.main
    },
    separatorCampos:{
        borderRightStyle: "solid",
        borderWidth: 1,
        borderRightColor: theme.palette.primary.main,
        borderButtonColor: theme.palette.main,
        borderBottomColor:theme.palette.primary.main ,
    },
    separatorCamposBottom:{
        borderWidth: 1,
        borderButtonColor: theme.palette.main,
        borderBottomColor:theme.palette.primary.main ,
    },
    header:{
        borderRightStyle: "solid",
        borderLeftStyle: "solid",
        borderTopStyle: "solid",
        borderBottomStyle: "solid",
        borderWidth: 1,
        borderRightColor: theme.palette.primary.main,
        borderLeftColor: theme.palette.primary.main,
        borderTopColor:theme.palette.primary.main ,
        borderBottomColor:theme.palette.primary.main ,
    },
    icon:{
        color: theme.palette.primary.main
    }
}));

export default function PacienteFicha(props) {
    const classes = useStyles();
    const [data, setData] = useState({});
    const [consultas, setConsultas] = useState([]);
    const [vacinas, setVacinas] = useState([]);
    const [estado, setEstado] = useState({
        cor: "",
        created_at: null,
        descricao: "",
        id: undefined,
        updated_at: "",
    });

    useEffect(()=>{
        api.get('/animal/'+props.match.params.id).then(res=>{
            setData(res.data.data.paciente)
            setConsultas(res.data.data.consultas)
            setVacinas(res.data.data.vacinas)
            setEstado(res.data.data.estado)
        })
    }, [props.match.params.id])

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Ficha de Paciente"}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} className={classes.separator}   >
                                <Typography component="span" variant="subtitle1">
                                    <b>Nome:</b> <br/>{data.nome}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.separator} >
                                <Typography  component="span" variant="subtitle1">
                                    <b>Data nascimento:</b> <br/>{moment(data.dataNascimento).format('DD/MM/YYYY')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Sexo:</b><br/>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={true}
                                                checked={data.sexo==="femea"}
                                            />
                                        }
                                        label={<FontAwesomeIcon className={classes.icon} size="lg" icon={faVenus}/>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={true}
                                                checked={data.sexo==="macho"}
                                            />
                                        }
                                        label={<FontAwesomeIcon style={{color: '#0088ff'}} size="lg" icon={faMars}/>}
                                    />
                                </Typography>

                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={4}  className={classes.separator}  >
                                <Typography  component="span" variant="subtitle1">
                                    <b>Especie:</b> <br/>{data.especie}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.separator} >
                                <Typography  component="span" variant="subtitle1">
                                    <b>Raça:</b> <br/>{data.raca}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} >
                                <Typography  component="span" variant="subtitle1">
                                    <b>Cor:</b> <br/>{data.cor}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.separator}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Peso atual:</b> <br/>{data.peso}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}  className={classes.separator}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Microchip:</b> <br/>{data.microchip!==null?data.microchip:<Divider className={classes.divisorVazio}/>}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}  >
                                <Typography  component="span" variant="subtitle1">
                                    <b>Esterilizado:</b> <br/>{data.esterilizado!=='nao'?"Sim":"Não"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={4} sm={4}  className={classes.separator}>
                                <Typography  component="span" variant="subtitle1" >
                                    <b>Estado do animal:</b> <br/><span style={{color: estado.cor}}>{estado.descricao}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} className={classes.separator} >
                                <Typography  component="span" variant="subtitle1">
                                    <b>Cliente:</b> <br/>{data.nomeTutor}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}  >
                                <Typography  component="span" variant="subtitle1">
                                    <b>Telemovel:</b> <br/>{data.telemovel}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={8}  className={classes.separator}  >
                                <Typography  component="span" variant="subtitle1">
                                    <b>Morada:</b> <br/>{data.morada}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Distrito:</b> <br/>{data.distrito}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={8}  className={classes.separator}  >
                                <Typography  component="span" variant="subtitle1">
                                    <b>Concelho:</b> <br/>{data.concelho}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography  component="span" variant="subtitle1">
                                    <b>Codigo Postal:</b>  <br/>{data.codigoPostal}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                                <br/>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography  component="h4" variant="h5">Historico do Paciente</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Grid container spacing={10}>
                                    <Grid item xs={12} sm={7}>
                                        <TableContainer component={Paper}>
                                            <h3><center>Consultas</center></h3>
                                            <Table>
                                                <TableHead>
                                                    <TableRow >
                                                        <TableCell className={classes.header}><b>Consulta (data)</b></TableCell>
                                                        <TableCell className={classes.header}><b>Peso</b></TableCell>
                                                        <TableCell className={classes.header}><b>Tipo consulta</b></TableCell>
                                                        <TableCell className={classes.header}><b>Aceder consulta</b></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        consultas.map((row, idx)=>(
                                                            <TableRow key={idx} >
                                                                <TableCell className={classes.separatorCampos}>{moment(row.dataInicio).format('DD/MM/YYYY')}</TableCell>
                                                                <TableCell className={classes.separatorCampos}>{row.peso}</TableCell>
                                                                <TableCell className={classes.separatorCampos}>{row.descricao}</TableCell>
                                                                <TableCell className={classes.separatorCamposBottom}><Link to={"/consulta/"+row.id}><Button variant={"contained"} color={"primary"}>Ver consulta</Button></Link></TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <TableContainer component={Paper}>
                                            <h3><center>Vacinas</center></h3>
                                            <Table>
                                                <TableHead>
                                                    <TableRow >
                                                        <TableCell className={classes.header}><b>Consulta (data)</b></TableCell>
                                                        <TableCell className={classes.header}><b>Peso</b></TableCell>
                                                        <TableCell className={classes.header}><b>Vacinas</b></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        vacinas.map((row, idx)=>(
                                                            <TableRow key={idx} >
                                                                <TableCell className={classes.separatorCampos}>{moment(row.dataInicio).format('DD/MM/YYYY')}</TableCell>
                                                                <TableCell className={classes.separatorCampos}>{row.peso}</TableCell>
                                                                <TableCell className={classes.separatorCamposBottom}>{row.vacina}</TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography  component="h4" variant="h5">Observações</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography>{data.observacoes}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider className={classes.divisor}/>
                                <br/>
                                <Divider className={classes.divisor}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Link  to={"/paciente/editar/" + props.match.params.id}><Button fullWidth={true} variant={"contained"} color={"primary"}>Editar</Button></Link>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </Container>
    );
}
