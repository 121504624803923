import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from './reportWebVitals';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register({
    onUpdate: registration => {
        alert('Acabou de sair uma nova atualização, por favor pressione o botão abaixo para atualizar');
        if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
        window.location.reload();
    }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();