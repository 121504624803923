import React, {useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import api, {auth, url} from '../../services/api'
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from "@material-ui/core/TableBody";
import {useHistory} from "react-router-dom"
import Swal from "sweetalert2";
import {CheckBox} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
    root:{
      minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "35vw",

    }
}));

export default function DocumentosCriar(props){
    const classes = useStyles();
    const [clientes, setClientes] = useState([]);
    const [artigos, setArtigos] = useState([]);
    const [tara, setTara] = useState({value:1, label:"Vendida"});
    const [contaCorrente, setContaCorrente] = useState(0);
    const [linhasArtigo, setLinhasArtigo] = useState([]);
    const [artigo, setArtigo] = useState([]);
    const [confirmado, setConfirmado] = useState(false);
    const [cliente, setCliente] = useState({
        "cliente": {
            "id": 0,
            "codigoCliente": "",
            "nome": "",
            "linhaPrecos": 1
        },
        "cc": 0
    });
    const [totalDoc, setTotalDoc] = useState(0.00);
    const [pagamento, setPagamento] = useState(false);
    const [importar, setImportar] = useState(false);
    const [clienteDefenido, defineCliente] = useState(false);
    const [edit, setEdit] = useState(false);
    const history = useHistory();
    const [consultas, setConsultas] = useState([]);
    const [infoArtigo, setInfoArtigo] = useState({
        preco: Number().toFixed(2),
        id: "",
        itemCode: "",
        descricao: "",
        unidade: "",
        estado: "",
        precos:[0,0,0,0,0],
        idFamilia: "",
        familia:"",
        Description: "",
        MaximumDecimals: "0",
        UnitOfMeasureID: "",
        desconto:0,
        quantidade:"",
    });

    useEffect(()=>{
        api.get('/cliente').then(res=>{
            if(res.data.code===0){
                setClientes(res.data.data)
            }
        })
        api.get('/item').then(res=>{
            if(res.data.code===0){
                setArtigos(res.data.data)
            }
        })
    },[props]);

    useEffect(()=>{
        if(importar===true){
            api.get('/consulta/cliente/'+cliente.cliente.id).then(res=>{
                if(res.data.code===0){
                    setConsultas(res.data.data)
                }
            })
        }
    },[importar, cliente.cliente.id])



    function defenirLinhas() {
        const temp = linhasArtigo;
        let total=0.00;
        infoArtigo.tipo=tara.value;
        temp.push({
            preco: infoArtigo.preco,
            id: infoArtigo.id,
            importado: false,
            descricao: infoArtigo.descricao,
            unidade: infoArtigo.unidade,
            estado: infoArtigo.estado,
            precos:infoArtigo.precos,
            idFamilia: infoArtigo.idFamilia,
            familia: infoArtigo.familia,
            desconto: infoArtigo.desconto,
            quantidade:Number(infoArtigo.quantidade).toFixed(parseFloat(infoArtigo.MaximumDecimals)),
            
            tipo:tara.value,
            Description:infoArtigo.Description,
            MaximumDecimals: infoArtigo.MaximumDecimals,
            UnitOfMeasureID: infoArtigo.UnitOfMeasureID,
        });
        //console.log(temp)

        for (let row of temp) {
            if(!isNaN(parseFloat(row.preco))) {
                total += calculatePrice(row.quantidade, row.preco, row.desconto)
            }else{
                total+=0
            }
        }

        setTotalDoc(total);
        setInfoArtigo({
            preco: Number().toFixed(2),
            id: "",
            itemCode: "",
            descricao: "",
            unidade: "",
            desconto: "0",
            estado: "",
            idFamilia: "",
            familia:"",
            precos:[0,0,0],
            Description: "",
            MaximumDecimals: "0",
            UnitOfMeasureID: "",
            quantidade:"",
        })
        setArtigo({})
        setLinhasArtigo(temp)
    }

    function  defineContaCorrente(data) {
        setContaCorrente(data.cc)
        setCliente(data)
        defineCliente(true)
        const tmp = infoArtigo;
        tmp.preco=parseFloat(tmp.precos[data.cliente.linhaPrecos-1]).toFixed(2)
        setInfoArtigo(tmp)
    }

    function defineArtigo(data) {
        setArtigo(data)
        data.quantidade = "";
        if(data.precos[cliente.cliente.linhaPrecos-1]!=""){
            data.preco=parseFloat(data.precos[cliente.cliente.linhaPrecos-1]).toFixed(2)
        }else{
            data.preco=parseFloat(data.precos[0]).toFixed(2)
        }
        let set = false
        const sistemaDesconto = JSON.parse(data.sistemaDesconto)
        for(let row of sistemaDesconto){
            if(row.linhaPreco==cliente.cliente.linhaPrecos){
                data.desconto=row.desconto
                set = true
            }
        }
        if(set==false)
            data.desconto=0
        setInfoArtigo(data);
        setTara({value:1, label:"Vendida"});
    }

    function definePreco(e) {
        if(!isNaN(Number(e.target.value))){
            if(Number(e.target.value)>=0) {
                setInfoArtigo({
                    ...infoArtigo,
                    preco: e.target.value
                })

            }
        }
    }

    function defineDesconto(e) {
        if(!isNaN(Number(e.target.value))){
            if(Number(e.target.value)>=0 && Number(e.target.value)<=100) {
                setInfoArtigo({
                    ...infoArtigo,
                    desconto:e.target.value
                })
            }
        }
    }

    function defineQuantidade(e) {
        if(e.target.value>=0){
            setInfoArtigo({
                ...infoArtigo,
                quantidade:e.target.value
            })
        }
    }

    async function guardarDoc(pago) {
        const data={};
        data.cliente=cliente.cliente.id;
        data.pago=pago
        data.linhas=linhasArtigo;
        data.confirmado=confirmado===true?2:1;
        const res =await api.post('/documentos', data);
        setPagamento(false)
        if(res.data.code===0){
            await Swal.fire({
                title: "Informação",
                text: res.data.message,
                icon:"success",
                showDenyButton: true,
                confirmButtonText:"Obter documento",
                denyButtonText:"Fechar",
            }).then(r=>{
                if(r.isConfirmed){
                    window.open(url+"pdf/docVenda/"+res.data.data+"/"+auth(), '_blank')
                    history.push("/documentos/ver/"+res.data.data)

                }else{
                    history.push("/documentos/ver/"+res.data.data)
                }
            })
        }else{
            await Swal.fire({
                title: "Informação",
                text: res.data.message,
                icon: "error",
                timer: 3000
            })
        }

    }

    function editarLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=linhasArtigo.filter((value, index) => {
            if(index===idx){
                setInfoArtigo(value);
            }else{
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total+=calculatePrice(row.quantidade, row.preco, row.desconto)
        }
        setTotalDoc(total)
        setLinhasArtigo(newTmp);
    }

    function removerLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp=linhasArtigo.filter((value, index) => {
            if (index !== idx) {
                return value;
            }
        })
        let total=0.00;
        for(let row of newTmp){
            total+=calculatePrice(row.quantidade, row.preco, row.desconto)
        }
        setTotalDoc(total)
        setLinhasArtigo(newTmp);
    }

    function removeZerosArtigo() {
        if(infoArtigo.preco===0 || infoArtigo.preco==="0.00"){
            setInfoArtigo({
                preco: "",
                id: infoArtigo.id,
                
                descricao: infoArtigo.descricao,
                unidade: infoArtigo.unidade,
                estado: infoArtigo.estado,
                idFamilia: infoArtigo.idFamilia,
                familia:infoArtigo.familia,
                precos: infoArtigo.precos,
                quantidade:infoArtigo.quantidade,
                tara:infoArtigo.tara,
                Description:infoArtigo.Description,
                MaximumDecimals: infoArtigo.MaximumDecimals,
                UnitOfMeasureID: infoArtigo.UnitOfMeasureID,
            })
        }
    }

    function importarArtigos(consulta){
        api.get('/consulta/materiais/'+consulta).then(res=>{
            if(res.data.code===0){
                const tmp=linhasArtigo;
                for(let artigo of res.data.data){
                    let infoArtigo = {
                        preco: artigo.precos[cliente.cliente.linhaPrecos-1],
                        id: artigo.id,
                        descricao: artigo.descricao,
                        unidade: artigo.unidade,
                        estado: artigo.estado,
                        precos:artigo.precos,
                        idFamilia: artigo.idFamilia,
                        familia: artigo.familia,
                        quantidade:Number(artigo.quantidade).toFixed(parseFloat(artigo.MaximumDecimals)),
                        Description:artigo.Description,
                        MaximumDecimals: artigo.MaximumDecimals,
                        UnitOfMeasureID: artigo.UnitOfMeasureID,
                        importado: artigo.registo
                    }
                    setLinhasArtigo((prevState =>[...prevState, infoArtigo]))
                    tmp.push(infoArtigo)
                }
                let total=0;
                for (let row of tmp) {
                    if(!isNaN(parseFloat(row.preco))) {
                        total += calculatePrice(row.quantidade, row.preco, row.desconto)
                    }else{
                        total+=0
                    }
                }
                setTotalDoc(total);
            }
        })
    }

    function calculatePrice(quantidade, preco, desconto=0){
        const precoFinal = parseFloat(quantidade)*parseFloat(preco);
        return precoFinal - (precoFinal*(parseFloat(desconto)/100))
    }

    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Criar registo <br/>
                </Typography>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    onChange={defineContaCorrente}
                                    className={classes.select}
                                    name="clientes"
                                    placeholder="Clientes"
                                    options={clientes}
                                    option={cliente}
                                    getOptionLabel={(option)=> option.cliente.nome}
                                    getOptionValue ={(option)=>option.cliente.id}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        Linha de precos em uso: {cliente.cliente.linhaPrecos}
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        Conta corrente: <span style={contaCorrente>0?({color: "red"}):null}>{contaCorrente.toFixed(2)}€</span>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        Confirmado: <Switch onChange={()=>setConfirmado(!confirmado)} checked={confirmado} color={"primary"}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container={true} spacing={2} xs={12} sm={12}>
                                    <Grid item xs={12} sm={12}>
                                        <Select
                                            onChange={defineArtigo}
                                            name="artigos"
                                            option={artigo}
                                            placeholder="Artigos"
                                            options={artigos}
                                            getOptionLabel={(option)=> option.artigo}
                                            getOptionValue ={(option)=>option.id}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Button onClick={()=>setImportar(true)} disabled={cliente.cliente.id===0}>Importar Consulta</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TableContainer component={Paper} style={{marginBottom: 10}}>
                                    <Table className={classes.table} aria-label="Descricao do artigo">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><b>Preço</b></TableCell>
                                                <TableCell><b>Desconto</b></TableCell>
                                                <TableCell><b>Unidade</b></TableCell>
                                                <TableCell><b>Quantidade</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell><TextField name="preco" onClick={removeZerosArtigo} disabled={tara.value!==1} onChange={definePreco} type="number" value={infoArtigo.preco}/></TableCell>
                                                <TableCell><TextField name="desconto" onChange={defineDesconto} type="number" value={infoArtigo.desconto}/></TableCell>
                                                <TableCell>{infoArtigo.UnitOfMeasureID}</TableCell>
                                                <TableCell><TextField name="quantidade" onChange={defineQuantidade} type="number"  value={infoArtigo.quantidade}/></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                 <Button onClick={defenirLinhas} disabled={infoArtigo.id === "" || parseFloat(infoArtigo.quantidade) <= 0 || infoArtigo.quantidade===""} fullWidth style={{marginTop: 20}} variant="contained" color="primary">Adicionar artigo</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="Artigos do documento">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell><b>Descrição</b></TableCell>
                                            <TableCell ><b>Preço</b></TableCell>
                                            <TableCell ><b>Desconto</b></TableCell>
                                            {edit===false?(<TableCell><b>Quantidade</b></TableCell>):(<TableCell colSpan={2}><b><center>Ações</center></b></TableCell>)}
                                            {edit===false?(<TableCell><b>Preço da linha</b></TableCell>):(null)}
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {linhasArtigo.map((row, index) =>
                                                    <TableRow key={index}>
                                                    <TableCell>{row.descricao}</TableCell>
                                                    <TableCell>{isNaN(parseFloat(row.preco))?"0.00":parseFloat(row.preco).toFixed(2)}€/{row.UnitOfMeasureID}</TableCell>
                                                    <TableCell>{row.desconto}</TableCell>
                                                    {edit===false?(<TableCell>{row.quantidade}</TableCell>):(<TableCell><Button onClick={()=>editarLinha(index)} variant="contained" color="primary">Editar linha</Button></TableCell>)}
                                                    {edit===false?(<TableCell>{isNaN(parseFloat(row.quantidade) * parseFloat(row.preco))?"0.00":calculatePrice(row.quantidade, row.preco, row.desconto).toFixed(2)}€</TableCell>):(<TableCell><Button variant="contained" color="secondary" className={classes.button} startIcon={<DeleteIcon />} onClick={() =>removerLinha(index)}>Remover linha</Button></TableCell>)}
                                            </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button onClick={()=>setPagamento(true)} disabled={linhasArtigo.length<= 0 || !clienteDefenido===true} fullWidth style={{marginTop: 20}} variant="contained" color="primary">Guardar registo total: {parseFloat(totalDoc).toFixed(2)}€</Button>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={edit}
                                            onChange={()=>setEdit(!edit)}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Editar documento"
                                />
                            </Grid>
                        </Grid>
                        </CardContent>
                </Card>
            </div>
            <Dialog
                open={pagamento}
                onClose={()=>setPagamento(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Guardar documento?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        O documento dá no total {parseFloat(totalDoc).toFixed(2)}€ deseja colocar em conta corrente ou pagar agora?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setPagamento(false)} color="primary">
                        Cancelar ação
                    </Button>
                    <Button onClick={()=>guardarDoc(false)} disabled={parseFloat(totalDoc)<=0} color="primary" autoFocus>
                        Conta corrente
                    </Button>
                    <Button onClick={()=>guardarDoc(true)} color="primary" autoFocus>
                        Pagamento agora
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={importar}
                onClose={()=>setImportar(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Importar"}</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Data</TableCell>
                                    <TableCell>Nome animal</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {consultas.map((row, idx)=>(
                                    <TableRow key={idx}>
                                        <TableCell>{row.data}</TableCell>
                                        <TableCell>{row.nome}</TableCell>
                                        <TableCell><Button color={"primary"} onClick={()=>importarArtigos(row.id)} variant={"outlined"}>Importar</Button></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setImportar(false)} color="primary">
                        Cancelar ação
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
