import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api, {url} from "../services/api";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {DialogTitle, TextField} from "@material-ui/core";
import { Calendar, Views } from 'react-big-calendar'
import * as BigCalendar from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Select from "react-select";
import DialogContentText from "@material-ui/core/DialogContentText";
import {useSnackbar} from "notistack";
import 'moment/locale/pt';
import {Link} from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import ClienteCriar from "./clientes/Criar";
import PacienteCriar from "./pacientes/Criar";
import {hexToRgb, isHexColor, setContrast} from "../colorCalculator";
import 'moment-timezone';


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '90vw',
        alignContent: "center"
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    height:{
        backgroundColor:"transparent",
        alignContent: "center",
        color:"white",
        height: 50,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        backgroundColor: "#e5007e",
        padding: theme.spacing(6),
        button: 0
    },
}));

export default function Dashboard(props) {

    const localizer = BigCalendar.momentLocalizer(moment)
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [infoConsulta, setInfoConsulta] = useState({
        "id": null,
        "start": "",
        "end": "",
        "title": "",
        "open":false,
        "nomeAnimal":"",
        "nomeCliente":"",
        "cliente":"",
        "tipoConsulta":"",
        "info":"",
        "doc":""
    })
    const [eventos, setEventos] = useState([]);
    const [range, setRange] = useState([moment().startOf('month').subtract(7, 'days').format('YYYY/MM/DD'), moment().endOf("month").add(7, 'days').format('YYYY/MM/DD')])
    const [apagar, setApagar] = useState(false);
    const [novoCliente, setNovoCliente] = useState(false);
    const [novoAnimal, setNovoAnimal] = useState(false);
    const [clientes, setclientes] = useState([]);
    const [tiposConsulta, setTiposConsulta] = useState([]);
    const [animais, setAnimais] = useState([]);
    const [start, setStart] = useState(false);
    const [agendarConsulta, setAgendarConsulta] = useState({
        start: "",
        end: "",
        open: false,
        cliente:null,
        funcionario: null,
        animal: null,
        tipoConsulta: null        ,
        info:""
    });
    const [editarConsulta, setEditarConsulta] = useState({
        start: "",
        end: "",
        open: false,
        cliente:null,
        funcionario: null,
        animal: null,
        tipoConsulta: null,
        info:""
    });
    const [funcionario, setFuncionario] = useState([]);
    let sub=false;

    function getCliente(){
        api.get('/cliente').then((res)=>{
            setclientes(res.data.data)
        })
    }

    function getTiposConsulta(){
        api.get('/tiposConsulta').then((res)=>{
            setTiposConsulta(res.data.data)
        })
    }

    useEffect(()=>{
        api.get('/consulta/pendentes?from='+range[0]+"&to="+range[1]).then((res)=>{
            const events = (res.data.data || []).map(item => {
                item['start']  = item['start'] && moment(item['start']).toDate()
                item['end'] = item['end'] && moment(item['end']).toDate()
                return item
            })
            setEventos(events)
        })
    }, [range])



    useEffect(()=>{
        getCliente();

        api.get('/user').then(res=>{
            setFuncionario(res.data.data)
        })
    }, [props.location]);

    useEffect(()=>{
        if(novoCliente===false) {
            getCliente()
            getTiposConsulta()
        }
        // eslint-disable-next-line
    }, [novoCliente, novoAnimal, agendarConsulta.cliente])

    useEffect(()=>{
        if(editarConsulta.cliente!==null && editarConsulta.cliente!=="") {
            api.get('animal/propriatario/' + editarConsulta.cliente).then(res=>{
                setAnimais(res.data.data)
            })
        }
    }, [editarConsulta.cliente])

    useEffect(()=>{
        if(agendarConsulta.cliente!==null && agendarConsulta.cliente!=="") {
            api.get('animal/propriatario/' + agendarConsulta.cliente).then(res=>{
                setAnimais(res.data.data)
            })
        }
    }, [agendarConsulta])


    function corEventos(event, start, end, isSelected){
        //var backgroundColor = event.estado==0?"#ff3ea2":"#dbbfce"
            let newStyle = {
                //backgroundColor: "#dbbfce",
                //color: "#000",
                borderRadius: "10px",
                border: "none"
            };

        let cor;
        if(event.estado==0){
            if(event.info===null) {
                if (isHexColor(event.corConsulta)) {
                    cor = hexToRgb(event.corConsulta)
                    cor=`rgb(${cor.r}, ${cor.g}, ${cor.b})`;
                } else {
                    cor = event.corConsulta;
                }
            }else{
                cor = hexToRgb("#0037ff");
                cor = `rgb(${cor.r}, ${cor.g}, ${cor.b})`
            }
        }else{
            cor=hexToRgb("#bababa");
            cor = `rgb(${cor.r}, ${cor.g}, ${cor.b})`
        }
        //console.log(event)

        if((event.importado!=0 && event.doc==1) || (event.doc!=null && event.doc!=1 && event.importado!=0)){
            cor=hexToRgb("#000000");
            cor = `rgb(${cor.r}, ${cor.g}, ${cor.b})`
        }

        newStyle.color=setContrast(cor)
        newStyle.backgroundColor= cor;
        return {
                className: "",
                style: newStyle
            };
    }


    function closeInfoConsulta(){
        setInfoConsulta({
            "id": null,
            "start": infoConsulta.start,
            "end": infoConsulta.end,
            "title": "",
            "cliente":null,
            "open":false,
            "nomeAnimal": "",
            "nomeCliente": "",
            tipoConsulta: null
        })
    }

    function novaConsulta(a){
        setAgendarConsulta({
            start:a.start,
            end: moment(a.end).add(30, 'minutes'),
            open: true,
            cliente:null,
            animal: null,
            funcionario: null,
            tipoConsulta: null,
            info:""


        });
    }

    function apagarConsulta(){
        api.delete('/consulta/'+infoConsulta.id).then(res=>{
            if(res.data.code===0){
                setApagar(false)
                setInfoConsulta({
                    "id": null,
                    "start": infoConsulta.start,
                    "end": infoConsulta.end,
                    "title": "",
                    "open":false,
                    "nomeAnimal": "",
                    "nomeCliente": "",
                    cliente: null
                })
                setEditarConsulta({
                    start: editarConsulta.start,
                    end: editarConsulta.end,
                    open: false,
                    cliente: null,
                    funcionario:null,
                    animal: null,
                })
                api.get('/consulta/pendentes').then((res)=>{
                    const events = (res.data.data || []).map(item => {
                        item['start']  = item['start'] && moment(item['start']).toDate()
                        item['end'] = item['end'] && moment(item['end']).toDate()
                        return item
                    })
                    setEventos(events)
                })

                enqueueSnackbar(res.data.message, { variant: "success", autoHideDuration: 1500 });


            }else{
                enqueueSnackbar(res.data.message, { variant: "error", autoHideDuration: 2500 });
            }
        })
    }

    function atualizarAgendamento(){
        if(sub===false){
            sub=true;
            setStart(true)
            console.log(agendarConsulta.start)
            api.put('consulta/agendar/'+infoConsulta.id, {
                funcionario : editarConsulta.funcionario,
                animal: editarConsulta.animal,
                dataInicio: moment(editarConsulta.start).tz('Europe/Lisbon').format('YYYY/MM/DD HH:mm:ss ZZ'),
                dataFim:moment(editarConsulta.end).tz('Europe/Lisbon').format('YYYY/MM/DD HH:mm:ss ZZ'),
                tipoConsulta: editarConsulta.tipoConsulta,
                info: editarConsulta.info
            }).then(res=>{
                sub=false;
                if(res.data.code===0){

                    enqueueSnackbar(res.data.message, { variant: "success", autoHideDuration: 1500 });

                    api.get('/consulta/pendentes').then((res)=>{
                        const events = (res.data.data || []).map(item => {
                            item['start']  = item['start'] && moment(item['start']).toDate()
                            item['end'] = item['end'] && moment(item['end']).toDate()
                            return item
                        })
                        setEventos(events)
                    })
                    setEditarConsulta({
                        start: editarConsulta.start,
                        end: editarConsulta.end,
                        open: false,
                        cliente:null,
                        funcionario: null,
                        animal: null
                    })
                    setInfoConsulta({
                        "id": null,
                        "start": "",
                        "end": "",
                        "title": "",
                        "open":false,
                        "nomeAnimal":"",
                        "nomeCliente":"",
                        "tipoConsulta": "",
                        "cliente": ""
                    })
                }else{
                    enqueueSnackbar(res.data.message, { variant: "error", autoHideDuration: 1500 });
                }
            })
            setStart(false)
        }

    }

    function realizarAgendamento(){
        if(sub===false){
            sub=true;
            setStart(true)
            api.post('consulta/agendar', {
                funcionario : agendarConsulta.funcionario,
                animal: agendarConsulta.animal,
                dataInicio: moment(agendarConsulta.start).tz('Europe/Lisbon').format('YYYY/MM/DD HH:mm:ss ZZ'),
                dataFim:moment(agendarConsulta.end).tz('Europe/Lisbon').format('YYYY/MM/DD HH:mm:ss ZZ'),
                tipoConsulta: agendarConsulta.tipoConsulta,
                info:agendarConsulta.info
            }).then(res=>{
                sub=false;
                if(res.data.code===0){
                    enqueueSnackbar(res.data.message, { variant: "success", autoHideDuration: 1500 });

                    api.get('/consulta/pendentes').then((res)=>{
                        const events = (res.data.data || []).map(item => {
                            item['start']  = item['start'] && moment(item['start']).toDate()
                            item['end'] = item['end'] && moment(item['end']).toDate()
                            return item
                        })
                        setEventos(events)
                    })
                    setAgendarConsulta({
                        funcionario: "",
                        start: agendarConsulta.start,
                        end: agendarConsulta.end,
                        animal: null,
                        cliente: null,
                        tipoConsulta:null,
                        open: false,
                        info:""

                    })
                }else{

                    enqueueSnackbar(res.data.message, { variant: "error", autoHideDuration: 1500 });

                }

            })
            setStart(false)
        }

    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Card className={classes.root}>
                        <CardContent style={{width: '90vw'}}>
                            <Typography component="h1" variant="h5">
                                Dashboard - Consultas
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={11} sm={11}>
                                    <Calendar
                                        onRangeChange={data=>setRange([moment(data.start).subtract(5, 'days').format('YYYY/MM/DD'), moment(data.end).add(5, 'days').format('YYYY/MM/DD')])}
                                        selectable

                                        onView={(view) => {
                                            console.log('#### onView');
                                            console.log('#### view=', view);
                                            //this.setState({currentView: view});
                                        }}

                                        localizer={localizer}
                                        events={eventos}
                                        defaultView={Views.MONTH}
                                        style = {{height: 700, width: '88vw', alignContent: "center"}}
                                        eventPropGetter={corEventos}
                                        messages={{
                                            date: 'Data',
                                            time: 'Hora',
                                            event: 'Eventos',
                                            allDay: 'Todos os dias',
                                            week: 'Semana',
                                            work_week: 'Semana de trabalho',
                                            day: 'Dia',
                                            month: 'Mês',
                                            previous: 'Anterior',
                                            next: 'Seguinte',
                                            yesterday: 'Ontem',
                                            tomorrow: 'Amanhã',
                                            today: 'Hoje',
                                            agenda: 'Agenda',
                                            noEventsInRange: 'Não tem consultas marcadas.',
                                        }}
                                        onSelectEvent={event => {
                                            event.open=true;
                                            setInfoConsulta(event)
                                        }}
                                        onSelectSlot={novaConsulta}
                                    />
                                    <a href={(url+"calendar").replace(window.location.protocol, 'webcal:')}><Button color={"primary"} variant={"contained"}>Obter calendario externo</Button></a>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>

                {/* Ver consulta */}
                <Dialog
                    open={infoConsulta.open}
                    onClose={closeInfoConsulta}
                >
                    <DialogTitle id="responsive-dialog-title">{"Detalhes da consulta nº "+infoConsulta.id}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} >
                            <Grid item xs={12}  container>
                                <Grid item container direction="row" spacing={2}>
                                    <Grid item xs={12} >
                                        <Typography gutterBottom variant="subtitle1">
                                            Nome do animal: <b>{infoConsulta.nomeAnimal}</b>
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Nome do dono: <b>{infoConsulta.nomeCliente}</b>
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            Data de inicio: <b>{moment(infoConsulta.start).format("DD/MM/yyyy")+ " ás "+ moment(infoConsulta.start).format("HH:mm")}</b>
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            Acaba ás: <b>{moment(infoConsulta.end).format("DD/MM/yyyy")+ " ás "+ moment(infoConsulta.end).format("HH:mm")}</b>
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            Realizada por: <b>{infoConsulta.nomeFuncionario}</b>
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            Tipo consulta: <b>{infoConsulta.tipoConsulta}</b>
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            Informação: <b>{infoConsulta.info}</b>
                                        </Typography>
                                    </Grid>
                                    <Grid  item xs={12}>
                                        <Grid container direction={"row"} xs={12} spacing={2}>
                                                {infoConsulta.estado!=1?(
                                                        <Grid item xs={4}>
                                                            <Button  onClick={()=>{
                                                                setEditarConsulta({
                                                                    end: infoConsulta.end,
                                                                    start: infoConsulta.start,
                                                                    open: true,
                                                                    funcionario: infoConsulta.funcionario,
                                                                    animal: infoConsulta.id_animal,
                                                                    cliente: infoConsulta.cliente,
                                                                    custo: infoConsulta.custo,
                                                                    info: infoConsulta.info
                                                                });
                                                            }} variant="contained" color={"primary"} style={{ cursor: 'pointer' }}>
                                                                Editar Marcação
                                                            </Button>
                                                        </Grid>
                                                ):null}
                                                {infoConsulta.doc!=0 && infoConsulta.doc!="1" && infoConsulta.doc!=null?(
                                                    <Grid item sm={4}>
                                                        <Link to={"/documentos/ver/"+infoConsulta.doc}>
                                                            <Button fullWidth variant="contained" color={"primary"} style={{ cursor: 'pointer' }}>
                                                                Ver <br/> registo
                                                            </Button>
                                                        </Link>
                                                    </Grid>
                                                ):null}
                                                <Grid item sm={4}>
                                                    <Link to={"/consulta/"+infoConsulta.id}>
                                                        <Button fullWidth variant="contained" color={"primary"} style={{ cursor: 'pointer' }}>
                                                            Realizar consulta
                                                        </Button>
                                                    </Link>
                                                </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeInfoConsulta} color="primary" autoFocus>
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Nova consulta */}
                <Dialog
                    open={agendarConsulta.open}
                    onClose={()=>{
                        setAgendarConsulta({
                            start: agendarConsulta.start,
                            end: agendarConsulta.end,
                            open: false,
                            cliente: "",
                            funcionario: null,
                            tipoConsulta: null
                        })
                    }}
                >
                    <DialogTitle id="responsive-dialog-title">{"Nova consulta para "+moment(agendarConsulta.start).format("DD/MM/YYYY")}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} >
                            <Grid item xs={12} container>
                                <Grid item xs={12} spacing={2}  container>
                                    <Grid item xs={6}>
                                        <KeyboardDateTimePicker
                                            variant="inline"
                                            format="DD/MM/YYYY HH:mm"
                                            margin="normal"
                                            openTo={"hours"}
                                            ampm={false}
                                            id="date-picker-inline"
                                            label="Data Inicio consulta"
                                            invalidDateMessage={"A data introduzida não é valida"}
                                            value={agendarConsulta.start}
                                            onChange={date=>{
                                                setAgendarConsulta({
                                                    start: date,
                                                    end: moment(date).add(30, 'minutes'),
                                                    open: agendarConsulta.open,
                                                    cliente: agendarConsulta.cliente,
                                                    funcionario: agendarConsulta.funcionario,
                                                    animal: agendarConsulta.animal,
                                                    tipoConsulta: agendarConsulta.tipoConsulta,
                                                    info: agendarConsulta.info

                                                })
                                            }}

                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <KeyboardDateTimePicker
                                            variant="inline"
                                            format="DD/MM/YYYY HH:mm"
                                            margin="normal"
                                            openTo={"hours"}
                                            invalidDateMessage={"A data introduzida não é valida"}
                                            ampm={false}
                                            id="date-picker-inline"
                                            label="Data Fim consulta"
                                            minDate={moment(agendarConsulta.start).add(5, 'minutes')}
                                            value={agendarConsulta.end}
                                            onChange={date=>{
                                                setAgendarConsulta({
                                                    start: agendarConsulta.start,
                                                    end: date,
                                                    open: agendarConsulta.open,
                                                    cliente: agendarConsulta.cliente,
                                                    funcionario: agendarConsulta.funcionario,
                                                    animal: agendarConsulta.animal,
                                                    tipoConsulta: agendarConsulta.tipoConsulta,
                                                    info: agendarConsulta.info
                                                })
                                            }}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            name="Funcionario"
                                            placeholder={"Funcionario"}
                                            options={funcionario}
                                            onChange={(op)=>setAgendarConsulta({
                                                start: agendarConsulta.start,
                                                end: agendarConsulta.end,
                                                open: agendarConsulta.open,
                                                cliente: agendarConsulta.cliente,
                                                funcionario: op.id,
                                                animal: agendarConsulta.animal,
                                                tipoConsulta: agendarConsulta.tipoConsulta,
                                                info: agendarConsulta.info
                                            })}
                                            menuPlacement="auto"
                                            maxMenuHeight={150}
                                            getOptionLabel={option => option.nome}
                                            getOptionValue={option => option.id}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container xs={12} spacing={2}>
                                            <Grid item xs={11}>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    name="cliente"
                                                    placeholder={"cliente"}
                                                    options={clientes}
                                                    onChange={(op)=>setAgendarConsulta({
                                                        start: agendarConsulta.start,
                                                        end: agendarConsulta.end,
                                                        open: agendarConsulta.open,
                                                        cliente: op.cliente.id,
                                                        funcionario: agendarConsulta.funcionario,
                                                        animal: agendarConsulta.animal,
                                                        tipoConsulta: agendarConsulta.tipoConsulta,
                                                        info: agendarConsulta.info
                                                    })}
                                                    menuPlacement="auto"
                                                    maxMenuHeight={150}
                                                    getOptionLabel={option => option.cliente.nome}
                                                    getOptionValue={option => option.cliente.id}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Button color={"primary"} onClick={()=>setNovoCliente(true)} variant={"contained"}><AddIcon/></Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container xs={12} spacing={2}>
                                            <Grid item xs={11}>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    name="Animal"
                                                    placeholder={"Animal"}
                                                    options={animais}
                                                    menuPlacement="auto"
                                                    maxMenuHeight={150}
                                                    getOptionLabel={option => option.nome}
                                                    getOptionValue={option => option.id}
                                                    onChange={(op)=>setAgendarConsulta({
                                                        start: agendarConsulta.start,
                                                        end: agendarConsulta.end,
                                                        open: agendarConsulta.open,
                                                        cliente: agendarConsulta.cliente,
                                                        funcionario: agendarConsulta.funcionario,
                                                        animal: op.id,
                                                        tipoConsulta: agendarConsulta.tipoConsulta,
                                                        info: agendarConsulta.info
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Button color={"primary"} onClick={()=>setNovoAnimal(true)} variant={"contained"}><AddIcon/></Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            name="Tipo consulta"
                                            placeholder={"Tipo de consulta"}
                                            options={tiposConsulta}
                                            menuPlacement="auto"
                                            maxMenuHeight={150}
                                            getOptionLabel={option => option.descricao}
                                            getOptionValue={option => option.id}
                                            onChange={(op)=>setAgendarConsulta({
                                                start: agendarConsulta.start,
                                                end: agendarConsulta.end,
                                                open: agendarConsulta.open,
                                                cliente: agendarConsulta.cliente,
                                                funcionario: agendarConsulta.funcionario,
                                                animal: agendarConsulta.animal,
                                                tipoConsulta: op.id,
                                                info: agendarConsulta.info
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label={"Informações"} fullWidth variant={"standard"} value={agendarConsulta.info} onChange={e=>setAgendarConsulta({
                                            start: agendarConsulta.start,
                                            end: agendarConsulta.end,
                                            open: agendarConsulta.open,
                                            cliente: agendarConsulta.cliente,
                                            funcionario: agendarConsulta.funcionario,
                                            animal: agendarConsulta.animal,
                                            tipoConsulta: agendarConsulta.tipoConsulta,
                                            info: e.target.value
                                        })} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button disabled={start} fullWidth={true} onClick={realizarAgendamento} variant={"contained"} color={"primary"}>Agendar consulta</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{
                            setAgendarConsulta({
                                start: agendarConsulta.start,
                                end: agendarConsulta.end,
                                open: false,
                                cliente: null,
                                funcionario:null,
                                animal: null
                            })
                        }} color="primary">
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Editar consulta */}
                <Dialog
                    open={editarConsulta.open}
                    onClose={()=>{
                        setEditarConsulta({
                            start: infoConsulta.start,
                            end: infoConsulta.end,
                            open: false,
                            cliente: "",
                            funcionario: null,
                            info:editarConsulta.info
                        })
                    }}
                >
                    <DialogTitle id="responsive-dialog-title">{"Editar consulta nº: "+infoConsulta.id}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} >
                            <Grid item xs={12} container>
                                <Grid item xs={12} spacing={2}  container>
                                    <Grid item xs={6}>
                                        <KeyboardDateTimePicker
                                            variant="inline"
                                            format="DD/MM/YYYY HH:mm"
                                            margin="normal"
                                            ampm={false}
                                            openTo={"hours"}
                                            label="Data Inicio consulta"
                                            invalidDateMessage={"A data introduzida não é valida"}
                                            value={editarConsulta.start}
                                            onChange={date=>{
                                                setEditarConsulta({
                                                    start: date,
                                                    end: moment(date).add(30, 'minutes'),
                                                    open: editarConsulta.open,
                                                    cliente: editarConsulta.cliente,
                                                    funcionario: editarConsulta.funcionario,
                                                    animal: editarConsulta.animal,
                                                    tipoConsulta: editarConsulta.tipoConsulta,
                                                    info:editarConsulta.info
                                                })
                                            }}

                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <KeyboardDateTimePicker
                                            variant="inline"
                                            format="DD/MM/YYYY HH:mm"
                                            margin="normal"
                                            openTo={"hours"}
                                            invalidDateMessage={"A data introduzida não é valida"}
                                            ampm={false}
                                            label="Data Fim consulta"
                                            minDate={moment(editarConsulta.start).add(30, 'minutes')}
                                            value={editarConsulta.end}
                                            onChange={date=>{
                                                setEditarConsulta({
                                                    start: editarConsulta.start,
                                                    end: date,
                                                    open: editarConsulta.open,
                                                    cliente: editarConsulta.cliente,
                                                    funcionario: editarConsulta.funcionario,
                                                    animal: editarConsulta.animal,
                                                    tipoConsulta: editarConsulta.tipoConsulta,
                                                    info:editarConsulta.info
                                                })
                                            }}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            name="Funcionario"
                                            placeholder={"Funcionario"}
                                            options={funcionario}
                                            menuPlacement="auto"
                                            maxMenuHeight={150}
                                            onChange={(op)=>setEditarConsulta({
                                                start: editarConsulta.start,
                                                end: editarConsulta.end,
                                                open: editarConsulta.open,
                                                cliente: editarConsulta.cliente,
                                                funcionario: op.id,
                                                animal: editarConsulta.animal,
                                                tipoConsulta: editarConsulta.tipoConsulta,
                                                info:editarConsulta.info
                                            })}
                                            /* eslint-disable-next-line array-callback-return */
                                            defaultValue={funcionario!==undefined?funcionario.filter(row=>{
                                                if(row.id==infoConsulta.funcionario) {
                                                    return row
                                                }
                                            }):null}
                                            getOptionLabel={option => option.nome}
                                            getOptionValue={option => option.id}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            name="Cliente"
                                            menuPlacement="auto"
                                            maxMenuHeight={150}
                                            placeholder={"Cliente"}
                                            options={clientes}
                                            /* eslint-disable-next-line array-callback-return */
                                            defaultValue={clientes!==undefined?clientes.filter(row=>{
                                                if(row.cliente.id==infoConsulta.cliente) {
                                                    return row
                                                }
                                            }):{}}
                                            onChange={(op)=>setEditarConsulta({
                                                start: editarConsulta.start,
                                                end: editarConsulta.end,
                                                open: editarConsulta.open,
                                                cliente: op.cliente.id,
                                                funcionario: editarConsulta.funcionario,
                                                animal: editarConsulta.animal,
                                                tipoConsulta: editarConsulta.tipoConsulta,
                                                info:editarConsulta.info
                                            })}
                                            getOptionLabel={option => option.cliente.nome}
                                            getOptionValue={option => option.cliente.id}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {animais.length!==0?(
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name="Animal"
                                                placeholder={"Animal"}
                                                menuPlacement="auto"
                                                maxMenuHeight={150}
                                                options={animais}
                                                /* eslint-disable-next-line array-callback-return */
                                                defaultValue={animais!==[]?animais.filter(row=>{
                                                    if(row.id==infoConsulta.id_animal) {
                                                        return row
                                                    }
                                                }):{}}
                                                getOptionLabel={option => option.nome}
                                                getOptionValue={option => option.id}
                                                onChange={(op)=>setEditarConsulta({
                                                    start: editarConsulta.start,
                                                    end: editarConsulta.end,
                                                    open: editarConsulta.open,
                                                    cliente: editarConsulta.cliente,
                                                    funcionario: editarConsulta.funcionario,
                                                    animal: op.id,
                                                    tipoConsulta: editarConsulta.tipoConsulta,
                                                    info:editarConsulta.info
                                                })}
                                            />
                                        ):null}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {tiposConsulta.length!==0?(
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name="Tipo consulta"
                                                placeholder={"Tipo de consulta"}
                                                options={tiposConsulta}
                                                menuPlacement="auto"
                                                defaultValue={tiposConsulta!==[]?tiposConsulta.filter(row=>{
                                                    if(row.id==infoConsulta.tipoConsultaId) {
                                                        return row
                                                    }
                                                }):{}}
                                                maxMenuHeight={150}
                                                getOptionLabel={option => option.descricao}
                                                getOptionValue={option => option.id}
                                                onChange={(op)=>setEditarConsulta({
                                                    start: editarConsulta.start,
                                                    end: editarConsulta.end,
                                                    open: editarConsulta.open,
                                                    cliente: editarConsulta.cliente,
                                                    funcionario: editarConsulta.funcionario,
                                                    animal: editarConsulta.animal,
                                                    tipoConsulta: op.id,
                                                    info:editarConsulta.info
                                                })}
                                            />
                                        ):null}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label={"Informações"} fullWidth variant={"standard"} value={editarConsulta.info} onChange={e=>setEditarConsulta({
                                            start: editarConsulta.start,
                                            end: editarConsulta.end,
                                            open: editarConsulta.open,
                                            cliente: editarConsulta.cliente,
                                            funcionario: editarConsulta.funcionario,
                                            animal: editarConsulta.animal,
                                            tipoConsulta: editarConsulta.tipoConsulta,
                                            info: e.target.value
                                        })} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button disabled={start} fullWidth={true} onClick={atualizarAgendamento} variant={"contained"} color={"primary"}>Atualizar consulta</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant={"contained"} onClick={()=>setApagar(true)} color={"secondary"}>Desmarcar Consulta</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{
                            setEditarConsulta({
                                start: editarConsulta.start,
                                end: editarConsulta.end,
                                open: false,
                                cliente: null,
                                funcionario:null,
                                animal: null,
                                info: ""
                            })
                        }} color="primary">
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Desmarcar consulta */}

                <Dialog
                    open={apagar}
                    onClose={()=>setApagar(false)}
                >
                    <DialogTitle id="alert-dialog-title">{"Deseja desmarcar a consulta "+infoConsulta.id+"?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Após precionar DESMARCAR não puderá cancelar a ação.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setApagar(false)} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={apagarConsulta} color="secondary">
                            Desmarcar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*Novo cliente*/}

                <Dialog
                    open={novoCliente}
                    onClose={()=>setNovoCliente(false)}
                >
                    <DialogTitle id="alert-dialog-title">{"Criar cliente"}</DialogTitle>
                    <DialogContent>
                        <ClienteCriar/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setNovoCliente(false)} color="primary">
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*Novo animal*/}

                <Dialog
                    open={novoAnimal}
                    onClose={()=>setNovoAnimal(false)}
                >
                    <DialogTitle id="alert-dialog-title">{"Registar Paciente"}</DialogTitle>
                    <DialogContent>
                        <PacienteCriar/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setNovoAnimal(false)} color="primary">
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>


            </Container>
        </MuiPickersUtilsProvider>

    );
}
