import React, {useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {TextField} from 'unform-material-ui';
import Select from "react-select";
import {Form} from '@unform/web';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import {useSnackbar} from "notistack";
import { useHistory } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function  PacienteEditar(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [dataNascimento, setDataNascimento] = useState(moment(new Date()));
    const [dono, setDono] = useState({cliente:{id:undefined}});
    const [sexo, setSexo] = useState({label:"", value:""});
    const [esterilizado, setEstrilizado] = useState({label:"", value:""});
    const [data, setdata]=useState({});
    const formRef = useRef(null);
    const [clientes, setClientes] = useState([]);
    const [estado, setEstado] = useState({value:undefined});
    const { enqueueSnackbar } = useSnackbar();
    const [estados, setEstados] = useState([{value:undefined}]);

    const history = useHistory();


    async function editarPaciente(data){
        setOpen(true);
        if(data.nome!==""){
            data.dataNascimento=(typeof dataNascimento === "string" )? dataNascimento:dataNascimento.format("YYYY-MM-DD");
            data.estado=estado.id;
            data.cliente=dono.cliente.id;
            data.sexo=sexo.value;
            data.esterilizado=esterilizado.value;

            const res = await api.put('/animal/'+props.match.params.id, data)
            if(res.data.code===0){
                enqueueSnackbar(res.data.message, { variant: "success", autoHideDuration: 1500 });
                if(props.consulta===undefined) {
                    history.push("/paciente/" + props.match.params.id);
                }else{
                    props.fn(false)
                }

            }else{
                enqueueSnackbar(res.data.message, { variant: "error", autoHideDuration: 2500 });

            }
        }
        setOpen(false);
    }

    useEffect(()=>{
        api.get('/animal/'+props.match.params.id).then(res=>{
            let clientID=0;
            let clientList=[];
            setDataNascimento(res.data.data.paciente.dataNascimento)
            api.get('/cliente').then(cli=> {
                clientList=cli.data.data;
                setClientes(cli.data.data)
                // eslint-disable-next-line
                const tutorTmp = cli.data.data.filter(row=>{
                    if(row.cliente.id==res.data.data.paciente.cliente) {
                        return row
                    }
                })
                setDono(tutorTmp[0])
            })

            api.get('/estados').then(est=>{
                setEstados(est.data.data)
                const tmpEstado=est.data.data.filter(row=>{
                    if(row.id==res.data.data.paciente.estado)
                        return row
                })
                console.log(tmpEstado[0])
                setEstado(tmpEstado[0])
            })
            if(res.data.code===0){
                clientID=res.data.data.paciente.cliente;
                formRef.current.setData(res.data.data.paciente);
                // eslint-disable-next-line array-callback-return
                clientList.map(row=>{
                    if(row.cliente.id===clientID)
                        setDono(row)
                })
                setdata(res.data.data.paciente)
            }else{
                enqueueSnackbar(res.data.message, { variant: "error", autoHideDuration: 2500 });
            }
        })
        // eslint-disable-next-line
    }, [props.match.params.id])

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Ficha de Paciente
                    </Typography>
                    <Form ref={formRef} className={classes.form} onSubmit={editarPaciente} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={7}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    label="Nome"
                                    name="nome"
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <DatePicker
                                    style={{marginTop: 0}}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="DD/MM/YYYY"
                                    margin="normal"
                                    invalidDateMessage={"A data não é valida"}
                                    label="Data nascimento"
                                    value={dataNascimento}
                                    onChange={date=>setDataNascimento(date)}

                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Especie"
                                    name="especie"
                                    list={"listaEspecie"}
                                    inputProps={{list:"listaEspecie"}}
                                />
                                <datalist id={"listaEspecie"}>
                                    <option value={"Gato"}>Gato</option>
                                    <option value={"Cão"}>Cão</option>
                                </datalist>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Raça"
                                    name="raca"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Cor"
                                    name="cor"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="microchip"
                                    name="microchip"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} style={{width:'25%'}} >
                                {clientes.length!==0?(
                                    <Select
                                        onChange={op=>setDono(op)}
                                        getOptionLabel={option => option.cliente.nome}
                                        getOptionValue={option => option.cliente.id}
                                        isLoading={clientes.length === 0}
                                        /* eslint-disable-next-line array-callback-return */
                                        defaultValue={clientes.filter(row=>{
                                            if(row.cliente.id==data.cliente) {
                                                return row
                                            }
                                        })}
                                        options={clientes}
                                        variant="outlined"
                                        required
                                        placeholder="Cliente"
                                    />
                                ):null}
                            </Grid>
                            <Grid item xs={12} sm={4} style={{width:'25%'}} >
                                {clientes.length!==0?(
                                    <Select isLoading={clientes.length === 0} defaultValue={data.sexo==="macho"?{label:"Macho", value:"macho"}:{label: "Fêmea", value: 'femea'}} onChange={op=>setSexo(op)} name="sexo" options={[{label:"Macho", value:"macho"}, {label: "Fêmea", value: 'femea'}]} placeholder="Sexo"/>
                                ):null}
                            </Grid>
                            <Grid item xs={12} sm={4} style={{width:'25%'}} >
                                {clientes.length!==0?(
                                    <Select isLoading={clientes.length === 0} defaultValue={data.esterilizado==="nao"?{label:"Não", value:"nao"}:{label: "Sim", value: 'sim'}} onChange={op=>setEstrilizado(op)} name="esterilizado" options={[{label:"Sim", value:"sim"}, {label: "Não", value: 'nao'}]} placeholder="Esterilizado"/>
                                ):null}
                            </Grid>
                            {estado.id!==undefined?(
                                <Grid item xs={12} sm={4} style={{width:'25%'}} >
                                    <Select
                                        onChange={op=>setEstado(op)}
                                        getOptionLabel={option => option.descricao}
                                        getOptionValue={option => option.id}
                                        options={estados}
                                        defaultValue={estado}
                                        variant="outlined"
                                        required
                                        placeholder="Estado do animal"/>
                                </Grid>
                            ):null}
                            <Grid item xs={12} sm={12}>
                                <Typography>Pós-cirugico:</Typography>
                                <TextField
                                    variant="filled"
                                    fullWidth
                                    name="posCirurgico"
                                    multiline
                                    rows={2}
                                    rowsMax={4}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography>Observações:</Typography>
                                <TextField
                                    variant="filled"
                                    fullWidth
                                    name="observacoes"
                                    multiline
                                    rows={2}
                                    rowsMax={4}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Editar paciente
                        </Button>
                    </Form>
                </div>
                <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </MuiPickersUtilsProvider>
    );
}
